import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { AppHeader } from '../../../components/src/AppHeader.web';
import CouponsController, { Coupon, Props } from './couponsController';
import { AppFooter } from '../../../components/src/AppFooter.web';
import { couponImg, profileCover } from './assets';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { getTranslationConfig } from '../../../components/src/helpers';

const outerBoxStyle: React.CSSProperties = {
  backgroundColor: "rgb(232, 246, 244)",
  padding: "50px 150px",
};

const innerBoxStyle: React.CSSProperties = {
  position: "relative" as "relative",
  background: "var(--Primary, #398378)",
  height: "213px",
  borderRadius: 10,
};

const absoluteDivStyle: React.CSSProperties = {
  position: 'absolute' as "absolute",
  padding: "20px",
  zIndex: 10,
};

const profileNameContainerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: '100%',
  background: `rgba(0, 0, 0, 0.3) url(${profileCover}) center bottom 30% / 100% auto no-repeat`,
  zIndex: 0,
};

const headingStyle: React.CSSProperties = {
  color: "#FFFFFF",
  fontFamily: '"Ubuntu"',
  fontSize: '36px',
  fontWeight: 700,
  lineHeight: '44px',
  letterSpacing: "-0.01em",
  textAlign: "center",
};

const commonStyles = {
  fontWeight: 400,
  fontSize: '16px',
  fontFamily: 'Ubuntu',
};

export default class Coupons extends CouponsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  navigationMap = {
    'Home': 'LandingPage',
    'My Profile': 'SpaUserProfile',
    'Coupons': 'CustomerCoupons',
  };

  handleNavigate = (route: string) => {
    this.props.navigation.navigate(route);
  };

  customerActionBlock = (coupon: Coupon, offText: string, couponsApplyText: string) => {
    const {
      id: couponId,
      code,
      discount,
      discount_type,
      min_cart_value,
    } = coupon;

    const {
      fromCustomerProfile,
    } = this.props;

    return fromCustomerProfile ? (
      <Typography
        style={{
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: "24px",
          fontFamily: 'Ubuntu',
          color: '#398378',
          textTransform: 'uppercase',
        }}
      >
        {discount_type === 'percentage'
          ? `${discount}% ${offText}`
          : `${this.currency}${discount} ${offText}`}
      </Typography>
    ) : (
      <Button
        data-test-id={'applyBtn'}
        variant='contained'
        style={{
          fontWeight: 700,
          fontSize: '16px',
          fontFamily: 'Ubuntu',
          backgroundColor: '#398378',
          borderRadius: '8px',
          color: '#FFFFFF',
          padding: '10px 16px',
        }}
        onClick={() =>
          this.applyCouponHandler(
            min_cart_value,
            couponId?.toString(),
            code,
            discount,
            discount_type,
          )
        }
      >
        {couponsApplyText}
      </Button>
    )
  }

  render() {
    const { t, dir, isArabic, transform } = getTranslationConfig();

    const offText = t['coupons-off-text'];
    const {
      error,
      coupons,
      showWarning,
      warningMinCartValue,
      warningCouponId,
    } = this.state;

    const {
      fromCustomerProfile,
    } = this.props;

    const id = this.state.isPreviewMode ? 'preview' : this.state.bookingId;

    return (
      <Box
        bgcolor='#E8F6F4'
        height='full'
      >
        {
          fromCustomerProfile ? (
            <>
              <AppHeader showLogin={false} forceUpdate={this.forceUpdate.bind(this)} />
              <Box
                dir={dir}
                style={outerBoxStyle}
              >
                <Box style={innerBoxStyle}>
                  <div style={absoluteDivStyle}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box display='flex' zIndex="100">
                        <BreadCrumbs
                          data-testid="customer-breadcrumbs"
                          items={[t['breadcrumbs-home'], t['breadcrumbs-my-profile'], t['breadcrumbs-coupons']]}
                          targetColor='#FFFFFF'
                          navigationMap={this.navigationMap}
                          navigate={this.handleNavigate}
                        />
                      </Box>
                    </Box>
                  </div>
                  <div className="profileNameContainer" style={profileNameContainerStyle}>
                    <div>
                      <Typography
                        style={headingStyle}
                        data-test-id="baner-heading"
                      >
                        {t['coupons-header-title']}
                      </Typography>
                    </div>
                  </div>
                </Box>
              </Box>
            </>
          ) : (
            <AppHeader forceUpdate={this.forceUpdate.bind(this)} />
          )
        }
        <Box
          dir={dir}
          mx='10rem'
          marginTop='40px'
        >
          <Box mb={2} mt={-2}>
            {!fromCustomerProfile && (
              <ArrowBackIcon
                style={{
                  padding: '10px',
                  cursor: 'pointer',
                  ...transform
                }}
                onClick={() => this.goToBookingsPage(id)}
              />
            )}
          </Box>
          <Typography
            variant='h2'
            style={{ fontWeight: 600, marginBottom: '30px' }}
          >
            {t['coupons-title']}
          </Typography>
          <Typography variant='body1' style={{ marginBottom: '2rem' }}>
            {t['coupons-description']}
          </Typography>
          {this.getLogicalValue(error, (
            <Typography
              className='error-message'
              variant='body2'
              style={{ color: '#E53E3E' }}
            >
              {error}
            </Typography>
          ), (
            coupons?.length ? (
              coupons.map((coupon, index) => {
                const {
                  id: couponId,
                  code,
                  discount,
                  discount_type,
                  valid_to,
                  min_cart_value,
                } = coupon;
                const locale = isArabic ? 'ar-eg' : 'en-US';

                const formattedExpiryDate = new Date(valid_to)
                  .toLocaleString(locale, {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                  })
                  .toUpperCase()
                  .replace(',', '');

                const timePart = new Date(valid_to).toLocaleString(locale, {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                });

                return (
                  <Box key={couponId + index} mb='1rem'>
                    <Box
                      bgcolor='#FFFFFF'
                      p='1rem'
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      borderRadius='8px'
                      boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
                    >
                      <Box display='flex' alignItems='center' py={3}>
                        <Box>
                          <img
                            src={couponImg}
                            alt='Coupon'
                            style={{
                              width: '50px',
                              height: '50px',
                              borderRadius: '8px',
                            }}
                          />
                        </Box>
                        <Box
                          mx='1rem'
                          width='1.3px'
                          height='50px'
                          bgcolor='black'
                        ></Box>
                        <Box data-test-id="coupon-details">
                          <Typography variant='body1'
                            style={{
                              ...commonStyles,
                              color: "#0F172A",
                              lineHeight: "24px"
                            }}
                          >
                            {t['coupons-min-booking']} {this.currency}{min_cart_value}
                            <Typography
                              component='span'
                              style={{
                                ...commonStyles,
                                color: '#398378',
                                marginLeft: '1rem',
                              }}
                              data-test-id="coupon-discount"
                            >
                              {!fromCustomerProfile && (
                                discount_type === 'percentage'
                                  ? `${discount}% ${offText}`
                                  : `${this.currency}${discount} ${offText}`
                              )}
                            </Typography>
                          </Typography>
                          <Typography
                            variant='body2'
                            style={{
                              ...commonStyles,
                              color: "#64748B",
                              lineHeight: "24px"
                            }}
                          >
                            {t['coupons-code']}:{' '}
                            <Typography
                              component='span'
                              style={{
                                fontWeight: 400,
                                fontSize: '16px',
                                color: '#0F172A',
                                fontFamily: "Ubuntu",
                                lineHeight: "24px"
                              }}
                            >
                              {code}
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                      {this.customerActionBlock(coupon, offText, t['coupons-apply'])}
                    </Box>
                    <Typography
                      data-test-id='expiry-text'
                      variant='body2'
                      style={{
                        marginTop: '1rem',
                        color: '#334155',
                        fontWeight: 400,
                        fontSize: '16px',
                      }}
                    >
                      {t['coupons-expiry']}:{' '}
                      <strong>
                        <Typography
                          component='span'
                          style={{ fontWeight: 'bold' }}
                        >
                          {formattedExpiryDate}
                        </Typography>
                      </strong>{' '}
                      {timePart}
                    </Typography>
                    {showWarning && warningCouponId === couponId?.toString() && (
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        bgcolor='#FEE2E2'
                        padding='0.5rem'
                        borderRadius='4px'
                        marginTop='1rem'
                        className='warning-message-box'
                      >
                        <Typography
                          className='warning-message-box'
                          variant='body2'
                          style={{ color: '#DC2626' }}
                        >
                          {t['coupons-warning-pre']} ${warningMinCartValue} {t['coupons-warning-post']}
                        </Typography>
                        <Typography
                          variant='body2'
                          data-test-id="hide-error"
                          style={{
                            color: '#DC2626',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                          onClick={() => this.setState({ showWarning: false })}
                        >
                          X
                        </Typography>
                      </Box>
                    )}
                  </Box>
                );
              })
            ) : (
              <Typography variant='body1'
                style={{
                  fontSize: '24px',
                  fontWeight: 700,
                  fontFamily: 'Ubuntu',
                  textAlign: 'center',
                  margin: '20px 0',
                }}>
                {t['coupons-no-coupons']}
              </Typography>
            )
          ))}
        </Box>
        <AppFooter navigation={this.props.navigation} />
      </Box>
    );
  }
}
