import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { FieldArray, Formik, Form } from "formik";
import * as Yup from "yup";

import {
  imgDollarSign,
  imgPendingClockGrey,
  imgPlaceholder,
  imgRemove,
  imgUploadGreen,
} from "../../settings1/src/assets";
import EditServiceController, {
  Catalogue,
  Props,
  Slots,
  configJSON,
  initCategory,
} from "./EditServiceController.web";
import { styles, ButtonComponent, StyledBox } from "./styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { durationList } from '../../../components/src/helpers';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Ubuntu",
    },
  },
});

export const initSettingsForm = {
  services: [
    {
      catalogueId: 0,
      serviceImage: null,
      serviceName: "",
      category: initCategory,
      subCategory: initCategory,
      targetedRegion: initCategory,
      serviceDescription: "",
      slots: [
        {
          id: 0,
          duration: "",
          price: "",
        },
      ],
    },
  ],
};

type ServiceError = {
  serviceImage: string;
  serviceName: string;
  category: { name: string };
  subCategory: { name: string };
  targetedRegion: string;
  serviceDescription: string;
  slots: {
    duration: string;
    price: string;
  }[];
};
// Customizable Area End

export default class EditService extends EditServiceController {
  // Customizable Area Start
  catalogueLoadingRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.catalogueLoadingRef = React.createRef();
    // Customizable Area End
  }
  // Customizable Area Start
  assignValues = async (setFieldValue: any) => {
    if (
      this.state.catalogues[0].id &&
      !this.catalogueLoadingRef.current
    ) {
      const catalogues = this.state.catalogues?.map(
        async (catalogue: Catalogue, index) => {
          const base64Image = await this.urlToFileObject(
            catalogue.attributes.images?.[0].url
          );
          const {
            category,
            sub_category: subCategory,
            catalogue_slots: catalogueSlots,
            region,
            name,
            description,
          } = catalogue.attributes;
          const slots = catalogueSlots.map((catSlot) => ({
            id: catSlot.id,
            duration: catSlot.duration,
            price: catSlot.price,
          }));

          const categoryItem = {
            id: category.attributes.id,
            name: category.attributes.name,
          };

          const subCategoryItem = {
            id: subCategory.id,
            name: subCategory.name,
          };

          const targetedRegionItem = {
            id: region?.id,
            name: region?.name,
          };

          this.handleImgUpload(base64Image, index);

          return {
            catalogueId: catalogue.id,
            serviceImage: base64Image,
            serviceName: name,
            category: categoryItem,
            subCategory: subCategoryItem,
            targetedRegion: targetedRegionItem,
            serviceDescription: this.stripTags(description),
            slots: slots,
          };
        }
      );

      // Wait for all Promises to complete
      const results = await Promise.all(catalogues || []);
      setFieldValue("services", results);

      this.catalogueLoadingRef.current = true;
    }
  };

  isRemoveVisible = (slotList: Slots[]) =>
    slotList.length > 1 ? "visible" : "hidden";

  isError = (first: boolean, second: boolean) => !!(first && second);
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <StyledBox bgcolor="#E8F6F4">
          <AppHeader />
          <Box style={styles.bodyDiv}>
            <Typography style={styles.formTitle}>Add Spa Service</Typography>
            <Formik
              initialValues={initSettingsForm}
              enableReinitialize={true}
              validationSchema={Yup.object().shape({
                services: Yup.array().of(
                  Yup.object().shape({
                    serviceName: Yup.string().required(
                      configJSON.emptyServiceName
                    ),
                    category: Yup.object().shape({
                      name: Yup.string().required(configJSON.emptyCategory),
                    }),
                    subCategory: Yup.object().shape({
                      name: Yup.string().required(configJSON.emptySubCategory),
                    }),
                    serviceDescription: Yup.string().required(
                      configJSON.emptyServiceDescription
                    ),
                    slots: Yup.array().of(
                      Yup.object().shape({
                        duration: Yup.string().required(
                          configJSON.emptyDuration
                        ),
                        price: Yup.string().required(configJSON.emptyPrice),
                      })
                    ),
                  })
                ),
              })}
              onSubmit={(values) => {
                this.callDeleteCatalogues();
                this.callCreateUpdateCatalogue(values.services);
              }}
            >
              {({
                handleChange,
                submitForm,
                errors,
                touched,
                handleBlur,
                setFieldValue,
                values,
              }) => {
                this.assignValues(setFieldValue);

                return (
                  <>
                    <FieldArray
                      name="services"
                      render={(arrayHelpers: any) => (
                        <>
                          {values.services.map(
                            (service, index, servicesList) => {
                              const {
                                serviceName: serviceNameError,
                                category: categoryError,
                                subCategory: subCategoryError,
                                serviceDescription: serviceDescriptionError,
                                slots: slotErrors,
                              } = (errors.services as ServiceError[])?.[
                              index
                              ] || {};

                              const {
                                serviceName,
                                category,
                                subCategory,
                                serviceDescription,
                                targetedRegion,
                                catalogueId,
                              } = service;

                              const serviceItemTouched =
                                touched.services?.[index];
                              const isServiceNameError = this.isError(
                                !!serviceItemTouched?.serviceName,
                                !!serviceNameError
                              );
                              const isCategoryError = this.isError(
                                !!serviceItemTouched?.category,
                                !!categoryError?.name
                              );
                              const isSubCategoryError = this.isError(
                                !!serviceItemTouched?.subCategory,
                                !!subCategoryError?.name
                              );
                              const isServiceDescriptionError = this.isError(
                                !!serviceItemTouched?.serviceDescription,
                                !!serviceDescriptionError
                              );

                              const categoryProp = `services.${index}.category`;
                              const subCategoryProp = `services.${index}.subCategory`;
                              const targetedRegionProp = `services.${index}.targetedRegion`;

                              return (
                                <Box key={index} style={styles.serviceForm}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      padding: "0 0.5rem 2.5rem",
                                    }}
                                  >
                                    <InputLabel
                                      style={{
                                        color: "#398378",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Service {index + 1}
                                    </InputLabel>
                                    {servicesList.length > 1 && (
                                      <img
                                        data-test-id="remove-img"
                                        src={imgRemove}
                                        alt="remove-image"
                                        style={styles.removeBin}
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                          this.removeImgUpload(index);
                                          this.setCatalogueIdsToDelete(
                                            catalogueId
                                          );
                                        }}
                                      />
                                    )}
                                  </Box>
                                  <Box
                                    width="330px"
                                    height="200px"
                                    position="relative"
                                  >
                                    <img
                                      src={
                                        this.state.upldImgLinks[index] ||
                                        values.services[index].serviceImage ||
                                        imgPlaceholder
                                      }
                                      alt="upload image"
                                      style={{
                                        width: "100%",
                                        height: "186px",
                                        objectFit: "cover",
                                        borderRadius: "8px",
                                      }}
                                    />
                                    <Button
                                      component="label"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "#D9EEEB",
                                        height: "44px",
                                        width: "100%",
                                        borderBottomLeftRadius: "8px",
                                        WebkitBorderBottomRightRadius: "8px",
                                        position: "absolute",
                                        bottom: 0,
                                        cursor: "pointer",
                                        textTransform: "none",
                                      }}
                                    >
                                      <input
                                        accept="image/*"
                                        data-test-id="spa-image-input"
                                        type="file"
                                        onChange={(event) => {
                                          const imageItem =
                                            event.target.files?.[0];
                                          this.handleImgUpload(
                                            imageItem,
                                            index
                                          );
                                          setFieldValue(
                                            `services.${index}.serviceImage`,
                                            imageItem
                                          );
                                        }}
                                        style={{
                                          visibility: "hidden",
                                          position: "absolute",
                                        }}
                                      />
                                      <img
                                        src={imgUploadGreen}
                                        alt="upload"
                                        style={{
                                          marginRight: "4px",
                                          display: "block",
                                          width: "16px",
                                          height: "15px",
                                        }}
                                      />
                                      <Typography
                                        style={{
                                          color: "#398378",
                                          fontWeight: 700,
                                        }}
                                      >
                                        Upload Image
                                      </Typography>
                                    </Button>
                                  </Box>
                                  <Box>
                                    <Box>
                                      <Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            paddingTop: "15px",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <InputLabel style={styles.formLabel}>
                                            Service Name
                                          </InputLabel>
                                          <InputLabel
                                            style={{ color: "#DC2626" }}
                                          >
                                            *
                                          </InputLabel>
                                        </Box>
                                        <TextField
                                          name={`services.${index}.serviceName`}
                                          value={serviceName}
                                          fullWidth={true}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          variant="outlined"
                                          data-test-id="service-name"
                                          placeholder={"Enter service name"}
                                          error={isServiceNameError}
                                          InputProps={{
                                            style: { borderRadius: "8px" },
                                          }}
                                        />
                                        <Typography style={styles.errorText}>
                                          {serviceItemTouched?.serviceName &&
                                            serviceNameError}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            paddingTop: "15px",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <InputLabel style={styles.formLabel}>
                                            Category
                                          </InputLabel>
                                          <InputLabel
                                            style={{ color: "#DC2626" }}
                                          >
                                            *
                                          </InputLabel>
                                        </Box>
                                        <Autocomplete
                                          data-test-id="service-category-input"
                                          value={category}
                                          fullWidth
                                          options={this.getSimpleCategories()}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionSelected={(option, value) =>
                                            option.id === value.id
                                          }
                                          autoComplete
                                          autoHighlight
                                          disableClearable
                                          disablePortal
                                          onChange={(_, newValue) => {
                                            setFieldValue(
                                              categoryProp,
                                              newValue
                                            );
                                            setFieldValue(subCategoryProp, {
                                              id: 0,
                                              name: "",
                                            });
                                          }}
                                          onBlur={handleBlur(categoryProp)}
                                          popupIcon={<ExpandMoreIcon />}
                                          renderInput={(params: any) => (
                                            <TextField
                                              {...params}
                                              placeholder="Select"
                                              error={isCategoryError}
                                              InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                style:
                                                  this.getAutocompleteStyle(
                                                    isCategoryError
                                                  ),
                                              }}
                                            />
                                          )}
                                        />
                                        <Typography style={styles.errorText}>
                                          {serviceItemTouched?.category &&
                                            categoryError?.name}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            paddingTop: "15px",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <InputLabel style={styles.formLabel}>
                                            Sub-Category
                                          </InputLabel>
                                          <InputLabel
                                            style={{ color: "#DC2626" }}
                                          >
                                            *
                                          </InputLabel>
                                        </Box>
                                        <Autocomplete
                                          data-test-id="service-subcategory-input"
                                          disabled={!category.name}
                                          value={subCategory}
                                          fullWidth
                                          options={this.getSubCategories(
                                            Number(category.id)
                                          )}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionSelected={(option, value) =>
                                            option.id === value.id
                                          }
                                          autoComplete
                                          autoHighlight
                                          disableClearable
                                          disablePortal
                                          onChange={(
                                            event: React.ChangeEvent<any>,
                                            newValue
                                          ) =>
                                            setFieldValue(
                                              subCategoryProp,
                                              newValue
                                            )
                                          }
                                          onBlur={handleBlur(subCategoryProp)}
                                          popupIcon={<ExpandMoreIcon />}
                                          renderInput={(params: any) => (
                                            <TextField
                                              {...params}
                                              placeholder="Select"
                                              error={isSubCategoryError}
                                              InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                style:
                                                  this.getAutocompleteStyle(
                                                    isSubCategoryError
                                                  ),
                                              }}
                                            />
                                          )}
                                        />
                                        <Typography style={styles.errorText}>
                                          {serviceItemTouched?.subCategory &&
                                            subCategoryError?.name}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            paddingTop: "15px",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <InputLabel style={styles.formLabel}>
                                            Targeted Region
                                          </InputLabel>
                                        </Box>
                                        <Autocomplete
                                          data-test-id="target-region-input"
                                          value={targetedRegion}
                                          fullWidth
                                          options={this.state.regions}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionSelected={(option, value) =>
                                            option.id === value.id
                                          }
                                          autoComplete
                                          autoHighlight
                                          disableClearable
                                          disablePortal
                                          onChange={(
                                            event: React.ChangeEvent<any>,
                                            newValue
                                          ) =>
                                            setFieldValue(
                                              targetedRegionProp,
                                              newValue
                                            )
                                          }
                                          onBlur={handleBlur(
                                            targetedRegionProp
                                          )}
                                          popupIcon={<ExpandMoreIcon />}
                                          renderInput={(params: any) => (
                                            <TextField
                                              {...params}
                                              placeholder="Select"
                                              InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                style:
                                                  this.getAutocompleteStyle(
                                                    false
                                                  ),
                                              }}
                                            />
                                          )}
                                        />
                                      </Box>
                                      <Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            paddingTop: "15px",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <InputLabel style={styles.formLabel}>
                                            Service Description
                                          </InputLabel>
                                          <InputLabel
                                            style={{ color: "#DC2626" }}
                                          >
                                            *
                                          </InputLabel>
                                        </Box>
                                        <TextField
                                          name={`services.${index}.serviceDescription`}
                                          value={serviceDescription}
                                          fullWidth={true}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          variant="outlined"
                                          data-test-id="service-description"
                                          placeholder={
                                            "Enter service description"
                                          }
                                          error={isServiceDescriptionError}
                                          InputProps={{
                                            style: { borderRadius: "8px" },
                                          }}
                                        />
                                        <Typography style={styles.errorText}>
                                          {serviceItemTouched?.serviceDescription &&
                                            serviceDescriptionError}
                                        </Typography>
                                      </Box>
                                      <Box display="flex" gridGap={8}>
                                        {/* Pricing */}
                                        <Box width="100%">
                                          <Box
                                            sx={{
                                              display: "flex",
                                              paddingTop: "15px",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <InputLabel
                                              style={styles.formLabel}
                                            >
                                              Set Pricing
                                            </InputLabel>
                                            <InputLabel
                                              style={{ color: "#DC2626" }}
                                            >
                                              *
                                            </InputLabel>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Form style={{ margin: 0 }}>
                                        <FieldArray
                                          name="slots"
                                          render={() => (
                                            <Box>
                                              {service.slots.map(
                                                (slot, slotIndex, slotList) => {
                                                  const {
                                                    duration: durationError,
                                                    price: priceError,
                                                  } =
                                                    slotErrors?.[slotIndex] ||
                                                    {};

                                                  const { duration, price } =
                                                    slot;

                                                  const isDurationError = !!(
                                                    serviceItemTouched?.slots?.[
                                                      slotIndex
                                                    ]?.duration && durationError
                                                  );
                                                  const isPriceError = !!(
                                                    serviceItemTouched?.slots?.[
                                                      slotIndex
                                                    ]?.price && priceError
                                                  );
                                                  return (
                                                    <Box
                                                      key={slotIndex}
                                                      display="flex"
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                      gridGap={4}
                                                      position="relative"
                                                      mb="1rem"
                                                    >
                                                      <Box minWidth="48%">
                                                        <Autocomplete
                                                          data-test-id="duration-input"
                                                          value={duration}
                                                          fullWidth
                                                          options={durationList}
                                                          autoComplete
                                                          autoHighlight
                                                          disableClearable
                                                          disablePortal
                                                          onChange={(
                                                            _,
                                                            newValue
                                                          ) =>
                                                            setFieldValue(
                                                              `services.${index}.slots.${slotIndex}.duration`,
                                                              newValue
                                                            )
                                                          }
                                                          onBlur={handleBlur(
                                                            `services.${index}.slots.${slotIndex}.duration`
                                                          )}
                                                          popupIcon={
                                                            <ExpandMoreIcon />
                                                          }
                                                          renderInput={(
                                                            params: any
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              placeholder="Duration"
                                                              error={
                                                                isDurationError
                                                              }
                                                              InputProps={{
                                                                ...params.InputProps,
                                                                disableUnderline:
                                                                  true,
                                                                style:
                                                                  this.getAutocompleteStyle(
                                                                    isDurationError
                                                                  ),
                                                                startAdornment:
                                                                  (
                                                                    <InputAdornment position="start">
                                                                      <img
                                                                        width={
                                                                          20
                                                                        }
                                                                        height={
                                                                          20
                                                                        }
                                                                        src={
                                                                          imgPendingClockGrey
                                                                        }
                                                                        alt=""
                                                                      />
                                                                    </InputAdornment>
                                                                  ),
                                                              }}
                                                            />
                                                          )}
                                                        />
                                                        <Typography
                                                          style={
                                                            styles.errorText
                                                          }
                                                        >
                                                          {serviceItemTouched
                                                            ?.slots?.[slotIndex]
                                                            ?.duration &&
                                                            durationError}
                                                        </Typography>
                                                      </Box>
                                                      <Box minWidth="48%">
                                                        <TextField
                                                          name={`services.${index}.slots.${slotIndex}.price`}
                                                          value={price}
                                                          fullWidth={true}
                                                          onChange={
                                                            handleChange
                                                          }
                                                          onBlur={handleBlur}
                                                          variant="outlined"
                                                          data-test-id="price-input"
                                                          placeholder={
                                                            "Enter price"
                                                          }
                                                          error={isPriceError}
                                                          InputProps={{
                                                            style: {
                                                              borderRadius:
                                                                "8px",
                                                            },
                                                            startAdornment: (
                                                              <InputAdornment position="start">
                                                                <p style={{ color: '#94A3B8' }}>{this.currency}</p>
                                                              </InputAdornment>
                                                            ),
                                                          }}
                                                        />
                                                        <Typography
                                                          style={
                                                            styles.errorText
                                                          }
                                                        >
                                                          {serviceItemTouched
                                                            ?.slots?.[slotIndex]
                                                            ?.price &&
                                                            priceError}
                                                        </Typography>
                                                      </Box>
                                                      <img
                                                        data-test-id="remove-img"
                                                        src={imgRemove}
                                                        alt="remove-image"
                                                        style={{
                                                          ...styles.removeBin,
                                                          visibility:
                                                            this.isRemoveVisible(
                                                              slotList
                                                            ),
                                                          position: "absolute",
                                                          right: "-28px",
                                                        }}
                                                        onClick={() => {
                                                          this.setSlotIdsToDelete(
                                                            slot.id,
                                                            index
                                                          );
                                                          slotList.splice(
                                                            slotIndex,
                                                            1
                                                          );
                                                          arrayHelpers.replace(
                                                            index,
                                                            {
                                                              ...service,
                                                              slots: slotList,
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    </Box>
                                                  );
                                                }
                                              )}

                                              <Button
                                                data-test-id="add-pricing-btn"
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                  arrayHelpers.replace(index, {
                                                    ...service,
                                                    slots: [
                                                      ...service.slots,
                                                      {
                                                        duration: "",
                                                        price: "",
                                                      },
                                                    ],
                                                  })
                                                }
                                                style={styles.morePricingBtn}
                                              >
                                                <AddCircleOutlineIcon
                                                  style={{
                                                    color: "#398378",
                                                    maxHeight: "22px",
                                                  }}
                                                />
                                                <Typography
                                                  style={{
                                                    marginLeft: "4px",
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Add more pricing
                                                </Typography>
                                              </Button>
                                            </Box>
                                          )}
                                        />
                                      </Form>
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                          <Box style={styles.addNewButton}>
                            <Button
                              data-test-id="add-service-btn"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                arrayHelpers.push(initSettingsForm.services[0]);
                              }}
                              style={styles.addServiceBtn}
                            >
                              <AddCircleOutlineIcon
                                style={{
                                  color: "#398378",
                                  maxHeight: "22px",
                                }}
                              />
                              <Typography
                                style={{
                                  marginLeft: "4px",
                                  fontWeight: 700,
                                }}
                              >
                                Add New Service
                              </Typography>
                            </Button>
                          </Box>
                        </>
                      )}
                    />
                    <Box mt={6}>
                      <ButtonComponent>
                        <Button
                          data-test-id="back-btn"
                          variant="contained"
                          color="primary"
                          onClick={() => this.goToBasicInformation(false)}
                          style={{
                            ...styles.formButton,
                            background: "#D9EEEB",
                            color: "#398378",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          data-test-id="submit-btn"
                          variant="contained"
                          color="primary"
                          onClick={submitForm}
                          style={styles.formButton}
                        >
                          {this.state.loading ? (
                            <CircularProgress style={{ color: "#FFFFFF" }} />
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </ButtonComponent>
                    </Box>
                  </>
                );
              }}
            </Formik>
          </Box>
        </StyledBox>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
