import React, { FC, ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { AppHeader } from '../../../../components/src/AppHeader.web';
import { BreadCrumbs } from '../../../../components/src/BreadCrumbs.web';
import { AppFooter } from '../../../../components/src/AppFooter.web';
import { startBookingHeader } from '../assets';
import { getTranslationConfig } from '../../../../components/src/helpers';

interface BookingWrapperProps {
    navigation: any;
    children: ReactNode;
    backgroundImage?: string;
    heading?: string;
    breadcrumbs?: string[];
    customStyles?: React.CSSProperties;
    navigationMap?: Record<string, string>;
    forceUpdate?: () => void;
}

export const BookingWrapper: FC<BookingWrapperProps> = ({
    navigation,
    children,
    backgroundImage,
    heading,
    breadcrumbs,
    customStyles,
    navigationMap,
    forceUpdate
}) => {
    const { dir } = getTranslationConfig();
    const defaultBackgroundImage = startBookingHeader;
    const defaultHeading = "My Bookings";
    const defaultBreadcrumbs = ['Home', 'Spa Profile', 'My Bookings'];

    const defaultStyles: React.CSSProperties = {
        background: `url(${backgroundImage || defaultBackgroundImage}) center/cover`,
        height: '150px',
        padding: '30px'
    };

    const mergedStyles = { ...defaultStyles, ...customStyles };

    const defaultNavigationMap = {
        'Home': 'LandingPage',
        'My Profile': 'SpaUserProfile',
        'My Bookings': 'MyBookings',
    };

    const handleNavigate = (route: string) => {
        navigation.navigate(route);
    };

    return (
        <Box>
            <Box>
                {/* Header */}
                <AppHeader forceUpdate={forceUpdate} />
                <Box
                    dir={dir}
                    py={3}
                    px='140px'
                >
                    <Box style={mergedStyles} data-testid="background-box">
                        <BreadCrumbs items={breadcrumbs || defaultBreadcrumbs} targetColor='#FFFFFF' navigationMap={navigationMap || defaultNavigationMap} navigate={handleNavigate} />                        <Typography
                            style={{
                                color: '#FFFFFF',
                                fontFamily: 'Ubuntu',
                                fontSize: '32px',
                                fontWeight: 700,
                                paddingTop: '30px',
                                textAlign: 'center'
                            }}
                            data-testid="heading"
                        >
                            {heading || defaultHeading}
                        </Typography>
                    </Box>
                    {children}
                </Box>
                {/* footer */}
                <AppFooter navigation={navigation} />
            </Box>
        </Box>
    );
};
