import React from "react";
import {
    Container,
    Box,
    TextField,
    Button,
    InputLabel,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import ContactusController, { Props } from "./ContactusController";
import { AppFooter } from '../../../components/src/AppFooter.web';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { ErrorComponent } from "./components/ErrorComponent";


const Body = styled('body')({
    backgroundColor: '#D9EEEB',
    height: "180vh"
});

export default class ContactUsPage extends ContactusController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Body>
                <AppHeader />
                <Container maxWidth="md" style={{
                    backgroundColor: "#FFF",
                    minHeight: "100vh",
                    marginBottom: "40px",

                }}>
                    {/* Customizable Area Start */}
                    <Box>
                        <p 
                        date-testID="contactUs"
                        style={{
                            fontFamily: 'Ubuntu',
                            fontWeight: 700,
                            fontSize: '48px',
                            lineHeight: '56px',
                            letterSpacing: '-1.5%',
                            padding: "20px"
                        }}>
                            Contact Us
                        </p>
                        <p style={{
                            fontFamily: 'Ubuntu',
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '24px',
                            paddingLeft: "20px",
                            marginTop: "-40px"
                        }}>
                            Fill in the form below, and we will get bak to you as soon as possible !
                        </p>
                    </Box>
                    <Box display="flex" flexDirection="column" padding={"20px"} >
                        <Box marginBottom={"20px"}>
                            <CustomInputLabel>
                                Name
                            </CustomInputLabel>
                            <TextField
                                placeholder="Enter your name"
                                variant="outlined"
                                fullWidth
                                name="name"
                                value={this.state.name}
                                onChange={this.handleInputChange}
                            />
                            <ErrorComponent
                                error={this.state.errors.name.message}
                                visible={this.state.errors.name.visible}
                            />
                        </Box>
                        <Box marginBottom={"20px"}>
                            <CustomInputLabel>
                                Email
                            </CustomInputLabel>
                            <TextField
                                placeholder="Enter your email id"
                                variant="outlined"
                                fullWidth
                                style={{
                                    borderRadius: "8px"
                                }}
                                name="email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                            />
                            <ErrorComponent
                                error={this.state.errors.email.message}
                                visible={this.state.errors.email.visible}
                            />
                        </Box>

                        <Box marginBottom={"20px"}>
                            <CustomInputLabel>
                                Contact Number
                            </CustomInputLabel>
                            <TextField
                                placeholder="Enter your contact number"
                                variant="outlined"
                                fullWidth
                                style={{
                                    borderRadius: "8px"
                                }}
                                name="contactNumber"
                                value={this.state.contactNumber}
                                onChange={this.handleInputChange}
                            />
                            <ErrorComponent
                                error={this.state.errors.contactNumber.message}
                                visible={this.state.errors.contactNumber.visible}
                            />
                        </Box>
                        <Box marginBottom={"20px"}>
                            <CustomInputLabel>
                                Inquiry title
                            </CustomInputLabel>
                            <TextField
                                placeholder="Enter inquiry title"
                                variant="outlined"
                                fullWidth
                                style={{
                                    borderRadius: "8px"
                                }}
                                name="inquiryTitle"
                                value={this.state.inquiryTitle}
                                onChange={this.handleInputChange}
                            />
                            <ErrorComponent
                                error={this.state.errors.inquiryTitle.message}
                                visible={this.state.errors.inquiryTitle.visible}
                            />
                        </Box>
                        <Box marginBottom={"20px"}>
                            <CustomInputLabel>
                                Inquiry Description
                            </CustomInputLabel>
                            <TextField
                                placeholder="Enter service description"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                style={{
                                    borderRadius: "8px"
                                }}
                                name="inquiryDescription"
                                value={this.state.inquiryDescription}
                                onChange={this.handleInputChange}
                            />
                            <ErrorComponent
                                error={this.state.errors.inquiryDescription.message}
                                visible={this.state.errors.inquiryDescription.visible}
                            />
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ alignSelf: "flex-start", marginTop: "20px", backgroundColor: "#2D6C67", color: "#FFF" }}
                            onClick={(event) => this.handleSubmit(event)}
                       >
                            Submit Inquiry
                        </Button>
                    </Box>
                    {/* Customizable End Start */}
                </Container>
                <AppFooter navigation={this.props.navigation} />
            </Body>      
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
const CustomInputLabel = styled(InputLabel)({
    fontWeight: 700,
    color: '#334155',
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    marginBottom: '5px',
});
// Customizable Area End