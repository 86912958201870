import React, { ReactNode, RefObject } from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  TextField,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Popover,
  MenuItem,
  Checkbox,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import * as Yup from "yup";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

import Settings1Controller, {
  CategorySlotsError,
  Props,
  ServiceError,
  Catalogue,
  configJSON,
  initSettingsForm,
  spaTimingSlots,
  initCatalogues,
  CarouselImage,
} from "./Settings1Controller";
import { ButtonComponent, RegistrationFormView, TypographyView, styles } from './styles';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import { styles as stylesOuter } from '../../../components/src/styles';
import { FieldArray, Form, Formik } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { addTimingIcon, arrowDown, calendarIcon, clockIcon, imgCalendar, imgDollarSign, imgMap, imgPendingClock, imgPendingClockGrey, imgPlaceholder, imgRemove, imgSpaEmpty, imgUploadGreen, timeRangeArrow, timerClock } from './assets';
import DatePicker from 'react-multi-date-picker';
import DurationPicker from 'react-duration-picker';
import '../../../components/src/durationPicker.css';
import '../../../components/src/calendar.css';
import './custom-styles.css';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { callAvailability } from '../../../blocks/utilities/src/call-availability';
import MapLocationAdv from '../../../components/src/MapLocationAdv';
import { durationList } from '../../../components/src/helpers';

export default class Settings1 extends Settings1Controller {
  calendarTimeRef: any;
  catAttemptRef: any;
  locationStepLoad: any;
  servicesStepLoad: any;
  availabilityStepLoad: any;
  timingStepLoad: any;
  imgStepLoad: any;
  cataloguesCall: any;
  calendarSlotRef: RefObject<{
    openCalendar: () => void;
    closeCalendar: () => void;
  }>[];

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.calendarTimeRef = React.createRef();
    this.catAttemptRef = React.createRef();

    this.cataloguesCall = React.createRef();

    this.locationStepLoad = React.createRef();
    this.servicesStepLoad = React.createRef();
    this.availabilityStepLoad = React.createRef();
    this.timingStepLoad = React.createRef();
    this.imgStepLoad = React.createRef();
    this.calendarSlotRef = [React.createRef()];
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getUserDetails();
    this.getCategories();
    this.getRegions();
  }
  // Handle catalogues data for 2nd, 3rd and final step
  async componentDidUpdate() {
    if (this.isCatalogueStep() && !this.cataloguesCall.current) {
      this.getCatalogues();
      this.cataloguesCall.current = true;
    }
  }
  // Customizable Area End

  render() {
    const steps = [
      'Add Spa Location',
      'Add Spa Services',
      'Spa Availability',
      'Spa Timings',
      'Add Spa Images'
    ];
    const isLocationStep = this.isLocationStep();
    const isServicesStep = this.isServicesStep();
    const isAvailabilityStep = this.isAvailabilityStep();
    const isTimingStep = this.isTimingStep();
    const isImagesStep = this.isImagesStep();
    const isFinalStep = this.isFinalStep();

    const { durationFrom, durationTo } = this.state.durationPicker;

    const getRegionsName = (regArray: { name: string }[]) =>
      regArray.length ?
        regArray.map((regValue: any) => regValue.name) :
        ['Default'];

    const {
      country: profileCountry,
      postal_code: profileState,
      city: profileCity,
      address: profileAddress
    } = this.state.userDetails?.attributes.profile_data.attributes || {};

    return (
      // Customizable Area Start
      <RegistrationFormView>
        <TypographyView>
          <Box style={styles.SafeAreaView} className='landing-venue-block'>
            <AppHeader />
            <Box py={6} px={28}>
              <Stepper activeStep={this.state.activeStep}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: 0,
                  marginBottom: '32px'
                }}>
                {steps.map((label, index) => (
                  <Step key={label} completed={this.checkStepCompleted(index)}>
                    <StepLabel style={{ color: '#000000' }}>{label}</StepLabel>
                  </Step>
                )
                )}
              </Stepper>
              {/* Heading */}
              <Typography
                style={{
                  fontFamily: 'Ubuntu',
                  fontSize: '24px',
                  fontWeight: 700,
                  padding: '16px 0',
                }}
              >Setup Spa Profile</Typography>
              <Formik
                initialValues={initSettingsForm}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                  ...(isLocationStep && {
                    country: Yup.string().required(configJSON.emptyCountry),
                    state: Yup.string().required(configJSON.emptyState),
                    city: Yup.string().required(configJSON.emptyCity),
                    completeAddress: Yup.string().required(configJSON.emptyCompleteAddress),
                  }),
                  ...(isServicesStep && {
                    services: Yup.array()
                      .of(Yup.object().shape({
                        serviceName: Yup.string().required(configJSON.emptyServiceName),
                        category: Yup.object()
                          .shape({
                            name: Yup.string().required(configJSON.emptyCategory),
                          }),
                        subCategory: Yup.object()
                          .shape({
                            name: Yup.string().required(configJSON.emptySubCategory),
                          }),
                        serviceDescription: Yup.string().required(configJSON.emptyServiveDescription),
                        slots: Yup.array()
                          .of(Yup.object().shape({
                            duration: Yup.string().required(configJSON.emptyDuration),
                            price: Yup.string().required(configJSON.emptyPrice),
                          }))
                      }))
                  }),
                  ...(isAvailabilityStep && {
                    categorySlots: Yup.array().of(
                      Yup.object().shape({
                        date: Yup.array().required(configJSON.emptyDate),
                        slotData: Yup.object().shape({
                          duration: Yup.string().required(configJSON.emptyDuration),
                        }),
                        availabilities: Yup.array().of(
                          Yup.object().shape({
                            timeSlots: Yup.array().required(configJSON.emptyAvailableSlots)
                          }))
                      })
                    ),
                  })
                })}
                onSubmit={(values) => {
                  if (isFinalStep) {
                    this.callUpdateActiveStep();
                    this.goToSettingsConfirmation();
                    this.resetDeleteIds();
                  } else {
                    isLocationStep && this.callUpdateProfileLocation(values);

                    if (isServicesStep) {
                      this.callCreateUpdateCatalogue(values);
                      this.callDeleteCatalogues();
                      this.resetCatalogues()
                      // Reload catalogs and refresh availability data with new changes
                      this.availabilityStepLoad.current = false;
                    };
                    if (isAvailabilityStep) {
                      callAvailability(values, this);
                    }

                    isTimingStep && this.callUpdateProfileTiming(values);

                    if (isImagesStep) {
                      this.callDeleteImages();
                      this.callUpdateProfileImages(values);
                    }

                    (isServicesStep || isAvailabilityStep) && this.callUpdateActiveStep();
                  }
                }}
              >
                {({
                  handleChange,
                  submitForm,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  values,
                  setTouched,
                }) => {
                  const {
                    country,
                    state,
                    city,
                    completeAddress
                  } = errors;
                  const isCountryError = this.getInputError(touched.country, country);
                  const isStateError = this.getInputError(touched.state, state);
                  const isCityError = this.getInputError(touched.city, city);
                  const isCompleteAddressError = this.getInputError(touched.completeAddress, completeAddress);

                  const cataloguePresent = this.state.catalogues[0]?.id;
                  const userDetailsPresent = this.state.userDetails;
                  const timingPresent = this.state.userDetails?.attributes.spa_timings;
                  const carouselImages = this.state.userDetails?.attributes.carousal_images;

                  const actlocationStep = isLocationStep && userDetailsPresent && !this.locationStepLoad.current
                  const actServiceStep = isServicesStep && cataloguePresent && !this.servicesStepLoad.current;
                  const actAvailabilityStep = isAvailabilityStep && cataloguePresent && !this.availabilityStepLoad.current;
                  const actTimingStep = isTimingStep && timingPresent && !this.timingStepLoad.current;
                  const actImagesStep = isImagesStep && carouselImages?.length && !this.imgStepLoad.current;

                  const timingDate = this.calendarTimeRef.current?.firstChild.defaultValue;
                  const initLocationAddress = this.state.locationAddress.address || profileAddress;
                  const initLocationCountry = this.state.locationAddress.country || profileCountry;
                  const initLocationState = this.state.locationAddress.state || profileState;
                  const initLocationCity = this.state.locationAddress.city || profileCity;

                  // Use loading indicator to determine whether formik step is changed to prevent validation for all the form
                  this.state.loading && Object.keys(touched).length && setTouched({});

                  if (actlocationStep) {
                    setFieldValue('country', initLocationCountry);
                    setFieldValue('state', initLocationState);
                    setFieldValue('city', initLocationCity);
                    setFieldValue('completeAddress', initLocationAddress);

                    profileCountry && this.onSelectCountry(profileCountry);
                    profileState && this.onSelectState(profileState);

                    this.locationStepLoad.current = true;
                  }

                  if (actServiceStep) {
                    const catalogues = this.state.catalogues.map((catalogue: Catalogue, index) => {
                      return this.urlToFileObject(catalogue.attributes.images?.[0].url)
                        .then(base64Image => {
                          const {
                            category,
                            sub_category: subCategory,
                            catalogue_slots: catalogueSlots,
                            region,
                            name,
                            description,
                          } = catalogue.attributes;
                          const slots = catalogueSlots.map((catSlot) => ({
                            id: catSlot.id,
                            duration: catSlot.duration,
                            price: catSlot.price
                          }));

                          const categoryItem = {
                            id: category.attributes.id,
                            name: category.attributes.name
                          };

                          const subCategoryItem = {
                            id: subCategory.id,
                            name: subCategory.name
                          };

                          const targetedRegionItem = {
                            id: region?.id,
                            name: region?.name
                          };

                          this.handleImgUpload(base64Image, index);

                          return ({
                            catalogueId: catalogue.id,
                            serviceImage: base64Image,
                            serviceName: name,
                            category: categoryItem,
                            subCategory: subCategoryItem,
                            targetedRegion: targetedRegionItem,
                            serviceDescription: this.stripTags(description),
                            slots: slots
                          })
                        })
                    });

                    // Wait for all Promises to complete
                    Promise.all(catalogues)
                      .then(results => {
                        setFieldValue('services', results);
                      })
                      .catch((error) => error);

                    this.servicesStepLoad.current = true;
                  }

                  if (actAvailabilityStep) {
                    const categorySlots = this.state.catalogues.map((catalogue: Catalogue, index) => {
                      // Create ref for each calendar per catalogue item
                      index && this.calendarSlotRef.push(React.createRef());

                      const attribs = catalogue.attributes;

                      // Show firstly availability with existed duration
                      const firstAvailability = attribs.availabilities.find(
                        (availability) => availability.duration
                      );

                      const preAvailabilities = attribs.availabilities.map((availability) => ({
                        availabilityId: availability.availability_id,
                        timeSlots: availability.timeslots,
                        slotId: availability.duration.id
                      }));

                      const initAvailabilities = catalogue.attributes.catalogue_slots.map((catalogue) => ({
                        availabilityId: 0,
                        timeSlots: [],
                        slotId: catalogue.id
                      }));

                      const availabilities = preAvailabilities.length ? preAvailabilities : initAvailabilities;

                      const initAttribs = initCatalogues[0].attributes;
                      const { category, name } = attribs || initAttribs;
                      const {
                        capacity,
                        dates,
                        timeslots,
                        duration: { id: slotId, duration: slotDuration },
                      } = firstAvailability || initAttribs.availabilities[0];

                      const timeStrings = this.generateTimeSlots(
                        slotDuration,
                        index,
                        timeslots
                      );
                      let indexes: number[] = [];
                      timeslots.forEach((timeSlot: string) => {
                        indexes = this.pushOrRemoveIndex(
                          indexes,
                          timeStrings.indexOf(timeSlot)
                        );
                        // Mitigate set state dellay to execute it
                        setTimeout(() => this.setSelectedSlotIndexes(indexes, slotId), 0);
                      });
                      const inputDates = dates.map((date: string) => new Date(date));
                      this.setSlotDates(inputDates, index);

                      return {
                        availabilities,
                        catalogueId: catalogue.id,
                        serviceName: name,
                        categoryName: category.attributes.name,
                        capacity: capacity,
                        date: dates,
                        slotData: {
                          id: slotId,
                          duration: slotDuration,
                        },
                      };
                    });
                    setFieldValue('categorySlots', categorySlots);

                    this.availabilityStepLoad.current = true;
                  }

                  if (actTimingStep) {
                    const spaTiming = this.state.userDetails?.attributes.spa_timings;
                    const [today, otherDays] = spaTiming || [];
                    const {
                      status,
                      start_time,
                      end_time,
                      date,
                      same_timing,
                    } = today || {};

                    const {
                      start_time: startOthers,
                      end_time: endOthers,
                      date: otherDate,
                      same_timing: otherSameTiming
                    } = otherDays || {};

                    const resDates = [...date, ...(otherDate || [])];
                    const todaysTiming = `${start_time} - ${end_time}`;
                    const currentTiming = `${startOthers || start_time} - ${endOthers || end_time}`;
                    const sameTiming = same_timing || otherSameTiming;

                    const schedulingDates = `${resDates[0]} ~ ${resDates[resDates.length - 1]}`;

                    const [hoursFrom, minutesFrom, markerFrom] = this.getHoursAndMinutes(startOthers || start_time);
                    const [hoursTo, minutesTo, markerTo] = this.getHoursAndMinutes(endOthers || end_time);

                    const durationFrom = {
                      hours: hoursFrom,
                      minutes: minutesFrom,
                      marker: markerFrom
                    };

                    const durationTo = {
                      hours: hoursTo,
                      minutes: minutesTo,
                      marker: markerTo
                    };

                    const timingObj = {
                      status,
                      todaysTiming,
                      dates: resDates,
                      currentTiming,
                      sameTiming
                    }
                    setFieldValue('timing', timingObj);

                    this.setTimingDate(schedulingDates);
                    this.setDurationPicker({
                      durationFrom,
                      durationTo,
                      hasStarted: true
                    });
                    this.timingStepLoad.current = true;
                  }

                  if (actImagesStep) {
                    const convCarouselImages = carouselImages?.map((carouselImage: CarouselImage) => {
                      return this.urlToFileObject(carouselImage.url)
                        .then((base64Image) => {
                          setFieldValue('spaImages', [...values.spaImages, base64Image]);
                          return carouselImage.url
                        })
                    });

                    Promise.all(convCarouselImages as any)
                      .then(
                        (carouselImages) => this.setUpldSPAImgLinks(carouselImages as any)
                      )
                      .catch((error) => error);

                    this.imgStepLoad.current = true;
                  };

                  const finalStepBtns = isFinalStep && {
                    cancel: 'Edit',
                    icon: <KeyboardArrowLeft />,
                    submit: 'Save & Publish',
                    cancelHandler: () => {
                      this.setEditMode();
                      this.setStep(0);
                    }
                  };

                  const imageStepBtns = isImagesStep && {
                    cancel: 'Skip',
                    submit: 'Next',
                    cancelHandler: () => this.setStep(this.state.activeStep + 1, this.callUpdateActiveStep)
                  }

                  const defaultBtns = {
                    cancel: 'Cancel',
                    submit: 'Next',
                    cancelHandler: () => this.goToLogin()
                  }

                  const buttonProperties: {
                    cancel: string,
                    icon?: ReactNode,
                    submit: string,
                    cancelHandler: () => void
                  } = finalStepBtns || imageStepBtns || defaultBtns;

                  this.checkFirstStep({
                    country: values.country,
                    state: values.state,
                    city: values.city,
                    address: values.completeAddress
                  });

                  return (
                    <>
                      {isLocationStep &&
                        <Box mt={3}>
                          <Box
                            style={styles.boxDevider}
                            display='flex'
                            alignItems='center'>
                            <Typography style={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 700, marginLeft: '16px' }}>
                              ADD LOCATION
                            </Typography>
                          </Box>
                          <Box className="mainView" style={styles.formBlock}>
                            <Box>
                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                <InputLabel className="testLabel">Pin your spa location</InputLabel>
                                <InputLabel className="testStar">*</InputLabel>
                              </Box>
                              <Box
                                height='234px'
                                width='100%'
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                onMouseDown={() => this.goToLocaton()}
                              >
                                {initLocationAddress ?
                                  <MapLocationAdv
                                    customStyles={{
                                      height: '234px',
                                      width: '100%',
                                      objectFit: 'cover',
                                      borderRadius: '16px',
                                      cursor: 'pointer'
                                    }}
                                    address={initLocationAddress}
                                  /> :
                                  <CircularProgress style={{ color: '#398378' }} />}
                              </Box>
                            </Box>
                            <Box>
                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                <InputLabel className="testLabel">Country</InputLabel>
                                <InputLabel className="testStar">*</InputLabel>
                              </Box>
                              <Autocomplete
                                data-test-id="country-input"
                                value={values.country}
                                disabled={isFinalStep}
                                fullWidth
                                options={getRegionsName(this.state.countries)}
                                className='inputeStyle'
                                autoComplete
                                autoHighlight
                                disableClearable
                                disablePortal
                                onChange={(event: React.ChangeEvent<any>) => {
                                  const country = event.target.textContent;
                                  setFieldValue('country', country);
                                  setFieldValue('state', '');
                                  setFieldValue('city', '');
                                  this.onSelectCountry(country);
                                }}
                                onBlur={handleBlur('country')}
                                popupIcon={<ExpandMoreIcon />}
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    placeholder='Select country'
                                    error={isCountryError}
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                      style: this.getAutocompleteStyle(isCountryError)
                                    }}
                                  />
                                )}
                              />
                              <Typography className="txtErrorMsg">{this.showByTouched(touched.country, country)}</Typography>
                            </Box>
                            <Box>
                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                <InputLabel className="testLabel">State</InputLabel>
                                <InputLabel className="testStar">*</InputLabel>
                              </Box>
                              <Autocomplete
                                data-test-id="state-input"
                                value={values.state}
                                fullWidth
                                disabled={isFinalStep}
                                options={getRegionsName(this.state.states)}
                                className='inputeStyle'
                                autoComplete
                                autoHighlight
                                disableClearable
                                disablePortal
                                onChange={(event: React.ChangeEvent<any>) => {
                                  const state = event.target.textContent;
                                  setFieldValue('state', state);
                                  setFieldValue('city', '');
                                  this.onSelectState(state)
                                }}
                                onBlur={handleBlur('state')}
                                popupIcon={<ExpandMoreIcon />}
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    placeholder='Select state'
                                    error={isStateError}
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                      style: this.getAutocompleteStyle(isStateError)
                                    }}
                                  />
                                )}
                              />
                              <Typography className="txtErrorMsg">{this.showByTouched(touched.state, state)}</Typography>
                            </Box>
                            <Box>
                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                <InputLabel className="testLabel">City</InputLabel>
                                <InputLabel className="testStar">*</InputLabel>
                              </Box>
                              <Autocomplete
                                data-test-id="city-input"
                                disabled={isFinalStep}
                                fullWidth
                                value={values.city}
                                options={getRegionsName(this.state.cities)}
                                className='inputeStyle'
                                autoComplete
                                autoHighlight
                                disableClearable
                                disablePortal
                                onChange={(event: React.ChangeEvent<any>) => setFieldValue('city', event.target.textContent)}
                                onBlur={handleBlur('city')}
                                popupIcon={<ExpandMoreIcon />}
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    placeholder='Select city'
                                    error={isCityError}
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                      style: this.getAutocompleteStyle(isCityError)
                                    }}
                                  />
                                )}
                              />
                              <Typography className="txtErrorMsg">{this.showByTouched(touched.city, city)}</Typography>
                            </Box>
                            <Box >
                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                <InputLabel className="testLabel">Complete Address</InputLabel>
                                <InputLabel className="testStar">*</InputLabel>
                              </Box>
                              <TextField
                                data-test-id="address-input"
                                name='completeAddress'
                                disabled={isFinalStep}
                                value={values.completeAddress}
                                fullWidth={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                                className="inputeStyle"
                                placeholder={"Enter customer name"}
                                error={isCompleteAddressError}
                                InputProps={{ style: { borderRadius: '8px' } }}
                              />
                              <Typography className="txtErrorMsg">{this.showByTouched(touched.completeAddress, completeAddress)}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      }

                      {isServicesStep &&
                        <Box mt={3}>
                          <Box style={styles.boxDevider} display='flex' alignItems='center'>
                            <Typography style={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 700, marginLeft: '16px' }}>
                              ADD SERVICES
                            </Typography>
                          </Box>
                          <Form>
                            <FieldArray
                              name="services"
                              render={(arrayHelpers: any) => (
                                <>
                                  {values.services.map((service, index, servicesList) => {
                                    const {
                                      serviceName: serviceNameError,
                                      category: categoryError,
                                      subCategory: subCategoryError,
                                      serviceDescription: serviceDescriptionError,
                                      slots: slotErrors
                                    } = (errors.services as ServiceError[])?.[index] || {};

                                    const {
                                      serviceName,
                                      category,
                                      subCategory,
                                      serviceDescription,
                                      targetedRegion,
                                      catalogueId
                                    } = service;

                                    const serviceItemTouched = touched.services?.[index];
                                    const isServiceNameError = this.getInputError(serviceItemTouched?.serviceName, serviceNameError);
                                    const isCategoryError = this.getInputError(serviceItemTouched?.category?.name, categoryError?.name);
                                    const isSubCategoryError = this.getInputError(serviceItemTouched?.subCategory?.name, subCategoryError?.name);
                                    const isServiceDescriptionError = this.getInputError(serviceItemTouched?.serviceDescription, serviceDescriptionError);

                                    const categoryProp = `services.${index}.category`;
                                    const subCategoryProp = `services.${index}.subCategory`;
                                    const targetedRegionProp = `services.${index}.targetedRegion`;

                                    return (
                                      <Box
                                        key={index}
                                        className="mainView"
                                        style={{
                                          backgroundColor: '#FFFFFF',
                                          borderBottom: 'none',
                                          marginTop: '24px',
                                          borderRadius: '8px',
                                          borderLeft: '4px solid #398378'
                                        }}>
                                        <Box sx={{ display: 'flex', padding: '15px 0', justifyContent: 'space-between', alignItems: 'center' }}>
                                          <InputLabel className="testLabel" style={{ color: '#398378' }} >Service {index + 1}</InputLabel>
                                          {servicesList.length > 1 &&
                                            !isFinalStep &&
                                            <img
                                              data-test-id='remove-img'
                                              src={imgRemove}
                                              alt='remove-image'
                                              style={styles.removeBin}
                                              onClick={() => {
                                                arrayHelpers.remove(index);
                                                this.removeImgUpload(index);
                                                this.setCatalogueIdsToDelete(catalogueId);
                                              }}
                                            />}
                                        </Box>
                                        <Box width='330px' height='200px' position='relative'>
                                          <img src={
                                            this.state.upldImgLinks[index] ||
                                            values.services[index].serviceImage ||
                                            imgPlaceholder
                                          }
                                            alt='upload image'
                                            style={{
                                              width: '100%',
                                              height: '186px',
                                              objectFit: 'cover',
                                              borderRadius: '8px'
                                            }} />
                                          <Button
                                            component='label'
                                            disabled={isFinalStep}
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              backgroundColor: '#D9EEEB',
                                              height: '44px',
                                              width: '100%',
                                              borderBottomLeftRadius: '8px',
                                              WebkitBorderBottomRightRadius: '8px',
                                              position: 'absolute',
                                              bottom: 0,
                                              cursor: 'pointer'
                                            }}
                                          >
                                            <input
                                              accept="image/*"
                                              data-test-id='spa-image-input'
                                              type="file"
                                              onChange={(event) => {
                                                const imageItem = event.target.files?.[0];
                                                this.handleImgUpload(imageItem, index);
                                                setFieldValue(`services.${index}.serviceImage`, imageItem);
                                              }}
                                              className="avtarImg"
                                              style={{
                                                visibility: 'hidden',
                                                position: 'absolute'
                                              }}
                                            />
                                            <img src={imgUploadGreen} alt='upload'
                                              style={{
                                                marginRight: '4px',
                                                display: 'block',
                                                width: '16px',
                                                height: '15px'
                                              }}
                                            />
                                            <Typography
                                              style={{
                                                color: '#398378',
                                                fontWeight: 700
                                              }}
                                            >
                                              Upload Image
                                            </Typography>
                                          </Button>

                                        </Box>
                                        <Box>
                                          <Box>
                                            <Box >
                                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                                <InputLabel className="testLabel">Service Name</InputLabel>
                                                <InputLabel className="testStar">*</InputLabel>
                                              </Box>
                                              <TextField
                                                name={`services.${index}.serviceName`}
                                                value={serviceName}
                                                fullWidth={true}
                                                disabled={isFinalStep}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className="inputeStyle"
                                                data-test-id="service-name"
                                                placeholder={"Enter service name"}
                                                error={isServiceNameError}
                                                InputProps={{ style: { borderRadius: '8px' } }}
                                              />
                                              <Typography className="txtErrorMsg">{this.showByTouched(serviceItemTouched?.serviceName, serviceNameError)}</Typography>
                                            </Box>
                                            <Box>
                                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                                <InputLabel className="testLabel">Category</InputLabel>
                                                <InputLabel className="testStar">*</InputLabel>
                                              </Box>
                                              <Autocomplete
                                                data-test-id="service-category-input"
                                                value={category}
                                                disabled={isFinalStep}
                                                fullWidth
                                                options={this.getSimpleCategories()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                className='inputeStyle'
                                                autoComplete
                                                autoHighlight
                                                disableClearable
                                                disablePortal
                                                onChange={(_, newValue) => {
                                                  setFieldValue(categoryProp, newValue);
                                                  setFieldValue(subCategoryProp, { id: 0, name: '' })
                                                }}
                                                onBlur={handleBlur(categoryProp)}
                                                popupIcon={<ExpandMoreIcon />}
                                                renderInput={(params: any) => (
                                                  <TextField
                                                    {...params}
                                                    placeholder='Select'
                                                    error={isCategoryError}
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      disableUnderline: true,
                                                      style: this.getAutocompleteStyle(isCategoryError)
                                                    }}
                                                  />
                                                )}
                                              />
                                              <Typography className="txtErrorMsg">{this.showByTouched(serviceItemTouched?.category?.name, categoryError?.name)}</Typography>
                                            </Box>
                                            <Box>
                                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                                <InputLabel className="testLabel">Sub-Category</InputLabel>
                                                <InputLabel className="testStar">*</InputLabel>
                                              </Box>
                                              <Autocomplete
                                                data-test-id="service-subcategory-input"
                                                disabled={!category.name || isFinalStep}
                                                value={subCategory}
                                                fullWidth
                                                options={this.getSubCategories(category.id)}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                className='inputeStyle'
                                                autoComplete
                                                autoHighlight
                                                disableClearable
                                                disablePortal
                                                onChange={(event: React.ChangeEvent<any>, newValue) => setFieldValue(subCategoryProp, newValue)}
                                                onBlur={handleBlur(subCategoryProp)}
                                                popupIcon={<ExpandMoreIcon />}
                                                renderInput={(params: any) => (
                                                  <TextField
                                                    {...params}
                                                    placeholder='Select'
                                                    error={isSubCategoryError}
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      disableUnderline: true,
                                                      style: this.getAutocompleteStyle(isSubCategoryError)
                                                    }}
                                                  />
                                                )}
                                              />
                                              <Typography className="txtErrorMsg">{this.showByTouched(serviceItemTouched?.subCategory?.name, subCategoryError?.name)}</Typography>
                                            </Box>
                                            <Box>
                                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                                <InputLabel className="testLabel">Targeted Region</InputLabel>
                                              </Box>
                                              <Autocomplete
                                                data-test-id="target-region-input"
                                                value={targetedRegion}
                                                disabled={isFinalStep}
                                                fullWidth
                                                options={this.state.regions}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                className='inputeStyle'
                                                autoComplete
                                                autoHighlight
                                                disableClearable
                                                disablePortal
                                                onChange={(event: React.ChangeEvent<any>, newValue) => setFieldValue(targetedRegionProp, newValue)}
                                                onBlur={handleBlur(targetedRegionProp)}
                                                popupIcon={<ExpandMoreIcon />}
                                                renderInput={(params: any) => (
                                                  <TextField
                                                    {...params}
                                                    placeholder='Select'
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      disableUnderline: true,
                                                      style: this.getAutocompleteStyle(false)
                                                    }}
                                                  />
                                                )}
                                              />
                                            </Box>
                                            <Box >
                                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                                <InputLabel className="testLabel">Service Description</InputLabel>
                                                <InputLabel className="testStar">*</InputLabel>
                                              </Box>
                                              <TextField
                                                name={`services.${index}.serviceDescription`}
                                                value={serviceDescription}
                                                disabled={isFinalStep}
                                                fullWidth={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className="inputeStyle"
                                                data-test-id="service-description"
                                                placeholder={"Enter service description"}
                                                error={isServiceDescriptionError}
                                                InputProps={{ style: { borderRadius: '8px' } }}
                                              />
                                              <Typography className="txtErrorMsg">{this.showByTouched(serviceItemTouched?.serviceDescription, serviceDescriptionError)}</Typography>
                                            </Box>
                                            <Box display='flex' gridGap={8}>
                                              {/* Pricing */}
                                              <Box width='100%'>
                                                <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                                  <InputLabel className="testLabel">Set Pricing</InputLabel>
                                                  <InputLabel className="testStar">*</InputLabel>
                                                </Box>
                                              </Box>
                                            </Box>
                                            <Form style={{ margin: 0 }}>
                                              <FieldArray
                                                name="slots"
                                                render={() => (
                                                  <Box>
                                                    {service.slots.map((slot, slotIndex, slotList) => {
                                                      const {
                                                        duration: durationError,
                                                        price: priceError
                                                      } = slotErrors?.[slotIndex] || {};

                                                      const {
                                                        duration,
                                                        price,
                                                      } = slot;

                                                      const isDurationError = this.getInputError(serviceItemTouched?.slots?.[slotIndex]?.duration, durationError);
                                                      const isPriceError = this.getInputError(serviceItemTouched?.slots?.[slotIndex]?.price, priceError);
                                                      return (
                                                        <Box
                                                          key={slotIndex}
                                                          display='flex'
                                                          justifyContent='space-between'
                                                          alignItems='center'
                                                          gridGap={4}
                                                          position='relative'
                                                        >
                                                          <Box minWidth='48%'>
                                                            <Autocomplete
                                                              data-test-id="duration-input"
                                                              value={duration}
                                                              disabled={isFinalStep}
                                                              fullWidth
                                                              options={durationList}
                                                              className='inputeStyle'
                                                              autoComplete
                                                              autoHighlight
                                                              disableClearable
                                                              disablePortal
                                                              onChange={(_, newValue) =>
                                                                setFieldValue(`services.${index}.slots.${slotIndex}.duration`, newValue)}
                                                              onBlur={handleBlur(`services.${index}.slots.${slotIndex}.duration`)}
                                                              popupIcon={<ExpandMoreIcon />}
                                                              renderInput={(params: any) => (
                                                                <TextField
                                                                  {...params}
                                                                  placeholder='Duration'
                                                                  error={isDurationError}
                                                                  InputProps={{
                                                                    ...params.InputProps,
                                                                    disableUnderline: true,
                                                                    style: this.getAutocompleteStyle(Boolean(isDurationError)),
                                                                    startAdornment: (
                                                                      <InputAdornment position="start">
                                                                        <img width={20} height={20} src={imgPendingClockGrey} alt="" />
                                                                      </InputAdornment>
                                                                    )
                                                                  }}
                                                                />
                                                              )}
                                                            />
                                                            <Typography className="txtErrorMsg">{this.showByTouched(serviceItemTouched?.slots?.[slotIndex]?.duration, durationError)}</Typography>
                                                          </Box>
                                                          <Box minWidth='48%'>
                                                            <TextField
                                                              name={`services.${index}.slots.${slotIndex}.price`}
                                                              value={price}
                                                              fullWidth={true}
                                                              disabled={isFinalStep}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              variant="outlined"
                                                              className="inputeStyle"
                                                              data-test-id="price-input"
                                                              placeholder={"Enter price"}
                                                              error={isPriceError}
                                                              InputProps={{
                                                                style: { borderRadius: '8px' },
                                                                startAdornment: (
                                                                  <InputAdornment position="start">
                                                                    <p style={{ color: '#94A3B8' }}>{this.currency}</p>
                                                                  </InputAdornment>
                                                                ),
                                                              }}
                                                            />
                                                            <Typography className="txtErrorMsg">{this.showByTouched(serviceItemTouched?.slots?.[slotIndex]?.price, priceError)}</Typography>
                                                          </Box>
                                                          {!isFinalStep && <img
                                                            data-test-id='remove-img'
                                                            src={imgRemove}
                                                            alt='remove-image'
                                                            style={{
                                                              ...styles.removeBin,
                                                              visibility: slotList.length > 1 ? 'visible' : 'hidden',
                                                              position: 'absolute',
                                                              right: '-28px'
                                                            }}
                                                            onClick={() => {
                                                              this.setSlotIdsToDelete(slot.id, index);
                                                              slotList.splice(slotIndex, 1);
                                                              arrayHelpers.replace(index, {
                                                                ...service,
                                                                slots: slotList
                                                              });
                                                            }}
                                                          />}
                                                        </Box>
                                                      )
                                                    })}
                                                    {!isFinalStep && <Button
                                                      data-test-id='add-pricing-btn'
                                                      variant="contained"
                                                      color="primary"
                                                      onClick={() => arrayHelpers.replace(index, {
                                                        ...service,
                                                        slots: [...service.slots, { duration: '', price: '' }]
                                                      })}
                                                      className="regButton"
                                                      style={{
                                                        background: '#F1F5F9',
                                                        color: '#047854',
                                                        textTransform: 'none',
                                                        marginTop: '20px'
                                                      }}
                                                    >
                                                      <AddCircleOutlineIcon
                                                        className="AddIconStyle" style={{
                                                          color: '#398378',
                                                          maxHeight: '22px'
                                                        }} />
                                                      <Typography style={{
                                                        marginLeft: '4px',
                                                        fontWeight: 700
                                                      }}>
                                                        Add more pricing
                                                      </Typography>
                                                    </Button>}
                                                  </Box>
                                                )}
                                              />
                                            </Form>
                                          </Box>
                                        </Box>
                                      </Box>
                                    )
                                  })}
                                  {!isFinalStep && <Button
                                    data-test-id='add-service-btn'
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      arrayHelpers.push({
                                        serviceName: '',
                                        category: '',
                                        subCategory: '',
                                        targetedRegion: '',
                                        serviveDescription: '',
                                        slots: [{
                                          duration: '',
                                          price: ''
                                        }]
                                      })
                                    }}
                                    className="regButton"
                                    style={{
                                      background: 'var(--Primary, #D9EEEB)',
                                      color: '#047854',
                                      marginTop: '32px',
                                      textTransform: 'none'
                                    }}
                                  >
                                    <AddCircleOutlineIcon
                                      className="AddIconStyle" style={{
                                        color: '#398378',
                                        maxHeight: '22px'
                                      }} />
                                    <Typography style={{
                                      marginLeft: '4px',
                                      fontWeight: 700
                                    }}>
                                      Add New Service
                                    </Typography>
                                  </Button>}
                                </>
                              )}
                            />
                          </Form>
                        </Box>
                      }

                      {isAvailabilityStep &&
                        <Box mt={3}>
                          <Box
                            style={styles.boxDevider}
                            display='flex'
                            alignItems='center'>
                            <Typography style={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 700, marginLeft: '16px' }}>
                              SET AVAILABILITY
                            </Typography>
                          </Box>
                          {/* Add Availability form */}
                          <Box>
                            <Box className="mainView"
                              style={{
                                ...styles.formBlock,
                                borderLeft: '4px solid #398378',
                                padding: '16px 24px'
                              }}>
                              {values.categorySlots.map((categorySlot, index) => {
                                const {
                                  date: dateError,
                                  availabilities,
                                  slotData,
                                } = (errors.categorySlots as CategorySlotsError[])?.[
                                index
                                ] || {};
                                const durationError = slotData?.duration;
                                // Retrieve any availability error if it's present
                                const availabilitiesError = availabilities?.find(
                                  (availablity) => Boolean(availablity?.timeSlots)
                                )?.timeSlots || '';
                                const isAvailabilitiesError = categorySlot.availabilities?.some(
                                  (availability) => !availability?.timeSlots.length
                                );

                                const {
                                  capacity,
                                } = categorySlot;

                                const isDurationSelected = Boolean(values.categorySlots[index].slotData.id);

                                const categorySlotsItemTouched = touched.categorySlots?.[index];
                                const isDateError = this.getInputError(Boolean(categorySlotsItemTouched?.date), dateError);
                                const isDurationError = this.getInputError(categorySlotsItemTouched?.slotData?.duration, durationError);

                                const isSlotsError = this.getInputError(
                                  !!categorySlotsItemTouched?.availabilities?.length,
                                  isAvailabilitiesError
                                );

                                const dateProp = `categorySlots.${index}.date`;
                                const slotDataProp = `categorySlots.${index}.slotData`;
                                const slotProp = `categorySlots.${index}.slots`;
                                const availabilitiesProp = `categorySlots.${index}.availabilities`;

                                return (
                                  <Accordion
                                    key={categorySlot.serviceName}
                                    style={{
                                      border: 'none',
                                      boxShadow: 'none'
                                    }}
                                    defaultExpanded={!index || isFinalStep}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1-content"
                                      id="panel1-header"
                                      style={{
                                        padding: '32px 0 16px 0',
                                        fontSize: '24px',
                                      }}
                                    >
                                      {categorySlot.categoryName}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Box>
                                        <Box display='flex' justifyContent='space-between' flexWrap='wrap' gridGap={8}>
                                          <Box width='48%'>
                                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                              <InputLabel className="testLabel">Service Name</InputLabel>
                                            </Box>
                                            <TextField
                                              name={`categorySlots.${index}.serviceName`}
                                              disabled
                                              value={categorySlot.serviceName}
                                              fullWidth={true}
                                              variant="outlined"
                                              className="inputeStyle"
                                              data-test-id="service-name"
                                              placeholder={"Enter service name"}
                                              InputProps={{ style: { borderRadius: '8px' } }}
                                            />
                                          </Box>
                                          <Box width='48%'>
                                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                              <InputLabel className="testLabel">Capacity</InputLabel>
                                            </Box>
                                            <TextField
                                              name={`categorySlots.${index}.capacity`}
                                              value={capacity || undefined}
                                              disabled={isFinalStep}
                                              fullWidth={true}
                                              type='number'
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              variant="outlined"
                                              className="inputeStyle"
                                              data-test-id="capacity"
                                              placeholder={"Enter capacity"}
                                              InputProps={{ style: { borderRadius: '8px' } }}
                                            />
                                          </Box>
                                          {/* DatePicker */}
                                          <Box width='48%' position='relative'>
                                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                              <InputLabel className="testLabel">Service Availability</InputLabel>
                                              <InputLabel className="testStar">*</InputLabel>
                                            </Box>
                                            <Box position='relative'>
                                              <img
                                                src={imgCalendar}
                                                alt="calendar"
                                                style={{
                                                  position: 'absolute',
                                                  cursor: 'pointer',
                                                  height: 24,
                                                  width: 24,
                                                  top: '50%',
                                                  transform: 'translateY(-50%)',
                                                  marginLeft: 8
                                                }}
                                                onClick={this.calendarSlotRef[index].current?.openCalendar}
                                              />
                                              <DatePicker
                                                key='date-picker-uniq329'
                                                className='basic-page-picker'
                                                ref={this.calendarSlotRef[index]}
                                                disabled={isFinalStep}
                                                data-test-id='date-picker'
                                                placeholder='Add your service days'
                                                style={this.getErrorBorder(isDateError)}
                                                inputClass="input-date-style"
                                                format="ddd"
                                                multiple
                                                range
                                                buttons
                                                monthYearSeparator=' '
                                                value={this.state.slotDates[index]}
                                                weekStartDayIndex={1}
                                                showOtherDays
                                                fixMainPosition
                                                calendarPosition='bottom-end'
                                                offsetX={120}
                                                onChange={
                                                  // Check dates type
                                                  (dates: any) => {
                                                    this.setDates(dates, (dates: string[]) => setFieldValue(dateProp, dates));
                                                    this.setSlotDates(dates, index);
                                                  }}
                                              >
                                                <Box borderTop='1px solid #E2E8F0' p={2}>
                                                  <ButtonComponent>
                                                    <Button
                                                      data-test-id='save-date-btn'
                                                      variant="contained"
                                                      color="primary"
                                                      onClick={this.calendarSlotRef[index].current?.closeCalendar}
                                                      className="regButton"
                                                      style={{
                                                        ...styles.formButton,
                                                        width: '100%'
                                                      }}
                                                    >
                                                      Save
                                                    </Button>
                                                  </ButtonComponent>
                                                </Box>
                                              </DatePicker>
                                            </Box>
                                            <Typography className="txtErrorMsg">{isDateError && dateError}</Typography>
                                          </Box>
                                          {/* Duration */}
                                          <Box minWidth='48%'>
                                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                              <InputLabel className="testLabel">Duration</InputLabel>
                                              <InputLabel className="testStar">*</InputLabel>
                                            </Box>
                                            <Autocomplete
                                              data-test-id="cat-duration-input"
                                              fullWidth
                                              options={this.state.catalogues[index]?.attributes.catalogue_slots || []}
                                              getOptionLabel={(option) => option.duration}
                                              disabled={isFinalStep}
                                              getOptionSelected={(option, value) => option.id === value.id}
                                              value={categorySlot.slotData}
                                              className='inputeStyle'
                                              autoComplete
                                              autoHighlight
                                              disableClearable
                                              disablePortal
                                              onChange={(_, newValue) => {
                                                const selectedAvailability = (this.state.catalogues[index]?.attributes || {}).availabilities?.find(item => (
                                                  item.duration.duration === newValue.duration
                                                ));
                                                const timeStrings = this.generateTimeSlots(
                                                  newValue.duration,
                                                  index,
                                                  selectedAvailability?.timeslots
                                                );
                                                setFieldValue(slotDataProp, {
                                                  id: newValue.id,
                                                  duration: newValue.duration,
                                                });
                                                // Reset selected slots
                                                let indexes: number[] = [];
                                                const slotIndexes = this.state.selectedSlotIndexes.find(slots => slots.id === newValue.id)?.indexes || [];
                                                selectedAvailability?.timeslots.forEach((timeSlot: string) => {
                                                  indexes = this.pushOrRemoveIndex(
                                                    indexes,
                                                    timeStrings.indexOf(timeSlot)
                                                  );
                                                  const resIndexes = slotIndexes.length ? slotIndexes : indexes;
                                                  this.setSelectedSlotIndexes(resIndexes, newValue.id);
                                                  setFieldValue(slotProp, resIndexes);
                                                });
                                              }}
                                              onBlur={handleBlur(slotDataProp)}
                                              popupIcon={<ExpandMoreIcon />}
                                              renderInput={(params: any) => (
                                                <TextField
                                                  {...params}
                                                  placeholder='Duration'
                                                  error={isDurationError}
                                                  InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                    style: this.getAutocompleteStyle(isDurationError),
                                                    startAdornment: (
                                                      <InputAdornment position="start">
                                                        <img width={20} height={20} src={imgPendingClockGrey} alt="" />
                                                      </InputAdornment>
                                                    )
                                                  }}
                                                />
                                              )}
                                            />
                                            <Typography className="txtErrorMsg">{this.showByTouched(isDurationError, durationError)}</Typography>
                                          </Box>
                                        </Box>
                                        {/* Time slots */}
                                        {isDurationSelected && <Box>
                                          <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                            <InputLabel className="testLabel">Available Slots</InputLabel>
                                            <InputLabel className="testStar">*</InputLabel>
                                          </Box>
                                          <Box display='flex' gridGap={16} flexWrap='wrap'>
                                            {/* Make duration relative slots */}
                                            {this.state.timeSlots[index].map((slot, slotIndex) =>
                                              <Chip
                                                data-test-id={`chip-${slotIndex}`}
                                                label={slot}
                                                variant="outlined"
                                                key={slot}
                                                style={{
                                                  height: '50px',
                                                  borderRadius: '8px',
                                                  border: '1px solid #398378',
                                                  fontSize: '16px',
                                                  cursor: 'pointer',
                                                  minWidth: '172px',
                                                  ...this.getHighlightSlotStyle(
                                                    slotIndex,
                                                    values.categorySlots[index].slotData.id,
                                                  ),
                                                }}
                                                onClick={() => {
                                                  // Set or remove slots
                                                  let availIndex = 0;
                                                  const copyAvailabilities = values.categorySlots[index].availabilities;
                                                  const copySlots = copyAvailabilities.find((availability, avIndex) => {
                                                    availIndex = avIndex;
                                                    return availability.slotId === values.categorySlots[index].slotData.id
                                                  })?.timeSlots || [];
                                                  const resultSlots = this.pushOrRemoveIndex(copySlots, slot);
                                                  (copyAvailabilities as any)[availIndex].timeSlots = resultSlots;

                                                  setFieldValue(
                                                    availabilitiesProp,
                                                    copyAvailabilities
                                                  );

                                                  const copyIndexes =
                                                    (this.state.selectedSlotIndexes.find((slots) => slots.id === values.categorySlots[index].slotData.id) || {}).indexes || [];
                                                  this.setSelectedSlotIndexes(
                                                    this.pushOrRemoveIndex(
                                                      copyIndexes,
                                                      slotIndex
                                                    ),
                                                    values.categorySlots[index].slotData.id
                                                  );
                                                }}
                                              />)}
                                          </Box>
                                          <Typography className="txtErrorMsg">{isSlotsError && availabilitiesError}</Typography>
                                          {!isFinalStep && <Button
                                            data-test-id='add-pricing-btn'
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleOpenDurationPopOver}
                                            className="regButton"
                                            style={{
                                              background: '#F1F5F9',
                                              color: '#047854',
                                              textTransform: 'none',
                                              marginTop: '20px',
                                              fontSize: '14px'
                                            }}
                                          >
                                            <AddCircleOutlineIcon
                                              className="AddIconStyle" style={{
                                                color: '#398378',
                                                maxHeight: '22px'
                                              }} />
                                            <Typography style={{
                                              marginLeft: '4px',
                                              fontWeight: 700
                                            }}>
                                              Add New
                                            </Typography>
                                          </Button>}
                                          <Popover
                                            open={Boolean(this.state.anchorDuration)}
                                            onClose={this.handleCloseDurationPopOver}
                                            anchorEl={this.state.anchorDuration}
                                            transformOrigin={{
                                              vertical: -35,
                                              horizontal: 'left',
                                            }}
                                            PaperProps={{
                                              style: {
                                                paddingTop: '32px',
                                                border: "1px solid #FFFFFF",
                                                borderBottomRightRadius: '24px',
                                                boxShadow: '2px 3px 10px 1px rgba(0,0,0,0.35)'
                                              }
                                            }}
                                          >
                                            <Box mt={2} display={"flex"} justifyContent={"center"} alignItems={"center"} style={{ gap: 32 }}>
                                              <Box
                                                data-test-id='duration-time-from'
                                                style={{
                                                  cursor: 'pointer',
                                                  color: this.getTimeColor({ isFromTime: this.state.isDurationFrom })
                                                }}
                                                onClick={() => this.setIsDurationFrom(true)}
                                              >
                                                <span
                                                  style={{
                                                    fontFamily: 'Ubuntu',
                                                    fontSize: 30
                                                  }}>
                                                  {durationFrom.hours}:{durationFrom.minutes}
                                                </span>
                                                <Box style={styles.dayPartWrapper}>
                                                  <span
                                                    style={{
                                                      ...styles.dayPart,
                                                      color: this.setAmPmColor('am', true)
                                                    }}
                                                    onClick={() => this.setTimeMarker('am', true)}
                                                  >AM</span>
                                                  <span
                                                    style={{
                                                      ...styles.dayPart,
                                                      color: this.setAmPmColor('pm', true)
                                                    }}
                                                    onClick={() => this.setTimeMarker('pm', true)}
                                                  >PM</span>
                                                </Box>
                                              </Box>
                                              <img src={timeRangeArrow} />
                                              <Box
                                                data-test-id='duration-time-to'
                                                style={{
                                                  cursor: 'pointer',
                                                  color: this.getTimeColor({ isFromTime: !this.state.isDurationFrom })
                                                }}
                                                onClick={() => this.setIsDurationFrom(false)}
                                              >
                                                <span
                                                  style={{
                                                    fontFamily: 'Ubuntu',
                                                    fontSize: 30,
                                                  }}>
                                                  {durationTo.hours}:{durationTo.minutes}
                                                </span>
                                                <Box style={styles.dayPartWrapper}>
                                                  <span
                                                    style={{
                                                      ...styles.dayPart,
                                                      color: this.setAmPmColor('am', false)
                                                    }}
                                                    onClick={() => this.setTimeMarker('am', false)}
                                                  >AM</span>
                                                  <span
                                                    style={{
                                                      ...styles.dayPart,
                                                      color: this.setAmPmColor('pm', false)
                                                    }}
                                                    onClick={() => this.setTimeMarker('pm', false)}
                                                  >PM</span>
                                                </Box>
                                              </Box>
                                            </Box>
                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                              <Box position={'relative'} display={"flex"} overflow={"hidden"} height={"142px"} alignItems="center" justifyContent={"center"}>
                                                <div className="duration-picker-container" style={{ position: "relative" }}>
                                                  {/* There is a bug with initial timing and digits position, the init values set fixes it partially */}
                                                  <DurationPicker
                                                    data-test-id='duration-picker'
                                                    maxHours={12}
                                                    initialDuration={{
                                                      hours: 10,
                                                      minutes: 13,
                                                      seconds: 0
                                                    }}
                                                    onChange={this.onDurationChange}
                                                  />
                                                </div>
                                              </Box>
                                            </Box>
                                            <Box py={2} px={4}>
                                              <ButtonComponent>
                                                <Button
                                                  data-test-id='save-date-btn'
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={() => this.handleTimeSlots(index)}
                                                  className="regButton"
                                                  style={{
                                                    ...styles.formButton,
                                                    width: '100%'
                                                  }}
                                                >
                                                  Save
                                                </Button>
                                              </ButtonComponent>
                                            </Box>
                                          </Popover>
                                        </Box>}
                                      </Box>
                                    </AccordionDetails>
                                  </Accordion>
                                )
                              })}
                            </ Box>
                          </Box>
                        </Box>
                      }

                      {isTimingStep &&
                        <Box mt={3}>
                          <Box
                            style={styles.boxDevider}
                            display='flex'
                            alignItems='center'>
                            <Typography style={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 700, marginLeft: '16px' }}>
                              SET AVAILABILITY
                            </Typography>
                          </Box>
                          <Box className="mainView"
                            style={{
                              ...styles.formBlock,
                              borderLeft: '4px solid #398378',
                              padding: '16px 24px'
                            }}>
                            <Typography style={{ fontSize: '24px', fontWeight: 700, margin: '16px 0' }}>
                              Spa Opening Hours
                            </Typography>
                            <Box sx={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
                              <InputLabel className="testLabel">{"Today Status"}{" "}</InputLabel>
                              <InputLabel className="testStar">{"*"}</InputLabel>
                            </Box>

                            <Autocomplete
                              data-test-id="spatiming-autocomplete"
                              fullWidth
                              disabled={isFinalStep}
                              options={['OPEN', 'CLOSED', 'PARTIALLY CLOSED']}
                              className='inputeStyle'
                              autoComplete
                              autoHighlight
                              disableClearable
                              disablePortal
                              value={values.timing.status.toUpperCase()}
                              style={{
                                color: this.SpaTodayStatusStyle(values.timing.status)
                              }}
                              onChange={(_, newValue) => setFieldValue('timing.status', newValue)}
                              onBlur={handleBlur('timing.status')}
                              popupIcon={<img width={25} height={25} src={arrowDown} alt="" />}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: {
                                      padding: '12px 16px',
                                      fontFamily: 'Ubuntu',
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      fontWeight: 700,
                                      color: this.SpaTodayStatusStyle(values.timing.status)
                                    },
                                  }}
                                />
                              )}
                              renderOption={(option) => (
                                <MenuItem style={{
                                  fontFamily: 'Ubuntu',
                                  fontWeight: 700,
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: this.SpaTodayStatusStyle(option)
                                }}>
                                  {option}
                                </MenuItem>
                              )}
                            />
                            <Box
                              p='12px 24px'
                              minWidth='376px'
                              maxWidth='520px'
                              maxHeight='76px'
                              borderRadius='30px'
                              border="1px solid #CBD5E1"
                              bgcolor='#ffffff'
                              whiteSpace='nowrap'
                              mb='16px'
                              mt='16px'
                              display='flex'
                              alignItems='center'
                              boxSizing='border-box'
                            >
                              <Box p={1} borderRadius='50%' bgcolor='#D1FAE5' display='inline-block' mr='14px'>
                                <img src={imgPendingClock} alt='map marker' />
                              </Box>
                              <Typography
                                style={{
                                  ...styles.regularMiddle,
                                  ...styles.dotsOverflow,
                                  display: 'inline-block',
                                  width: '80%'
                                }}>
                                Todays timing  <span style={{ fontWeight: 'bold' }}>
                                  {values.timing.todaysTiming}
                                </span>
                              </Typography>
                              <Box p={1} className="buttonCursor" display='inline-block' mr='14px' position={"relative"}>
                                <img
                                  data-test-id='timing-popover-img'
                                  src={arrowDown}
                                  style={{ cursor: 'pointer' }}
                                  onClick={(event: React.MouseEvent<HTMLImageElement>) => !isFinalStep && this.handleOpenTimingPopOver(event)}
                                />
                                <Popover
                                  open={Boolean(this.state.anchorTiming)}
                                  onClose={this.handleCloseTimingPopOver}
                                  anchorEl={this.state.anchorTiming}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  PaperProps={{
                                    style: { marginTop: 48, marginLeft: 18, width: '220px', boxShadow: 'none', borderRadius: "8px", border: "1px solid #CBD5E1" }
                                  }}
                                >
                                  <div style={{ border: "1px solid #CBD5E1", borderRadius: 4, padding: 4, display: 'flex', flexDirection: 'column' }}>
                                    <Box bgcolor="#FFFFFF" width={"220px"} >
                                      {spaTimingSlots.map((item, index) =>
                                        <Box
                                          key={item.label}
                                          test-data-id="spa-timing-slot"
                                          className="addTimingOpen"
                                          display="flex"
                                          alignItems="center"
                                          p={"12px 16px"}
                                          style={{
                                            cursor: 'pointer',
                                            gap: 8
                                          }}
                                          onClick={() => {
                                            this.handleCloseTimingPopOver();
                                            setFieldValue('timing.todaysTiming', item.label);
                                          }}
                                        >
                                          <img src={clockIcon} />
                                          <Typography style={{ fontFamily: 'Ubuntu', fontSize: 14 }}>
                                            {item.label}
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                  </div>
                                </Popover>
                              </Box>
                            </Box>
                            <Box display='flex' justifyContent='space-between' gridGap={16}>
                              <Box minWidth='48%'>
                                {/* DatePicker */}
                                <Box
                                  data-test-id='calendar-timing-block'
                                  style={{
                                    cursor: 'pointer',
                                    position: 'relative'
                                  }}
                                  {...stylesOuter.clickBlock}
                                  onClick={() => {
                                    this.calendarTimeRef.current.openCalendar();
                                  }}
                                >
                                  <Box
                                    p={1}
                                    borderRadius='50%'
                                    bgcolor='#FEF3C7'
                                    mr='14px'
                                  >
                                    <img
                                      data-test-id="add-timing-img"
                                      src={addTimingIcon}
                                      alt='map marker'
                                      style={{
                                        cursor: 'pointer'
                                      }}
                                    />
                                  </Box>
                                  <Typography
                                    style={{
                                      ...styles.regularMiddle,
                                      ...styles.dotsOverflow,
                                      color: '#94A3B8',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      width: '68%'
                                    }}>
                                    {this.getTrullyVal(timingDate || this.state.timingDate, 'Add Date')}
                                  </Typography>
                                  <Box
                                    p={1}
                                    className="buttonCursor"
                                    display='inline-block'
                                    mr='14px'
                                    position='absolute'
                                    right={0}
                                  >
                                    <img data-test-id="calendar-icon-btn" src={calendarIcon} alt='map marker' />
                                  </Box>
                                </Box>
                                <Box position='relative' className='custom-calendar'>
                                  <DatePicker
                                    ref={this.calendarTimeRef}
                                    data-test-id='date-picker'
                                    className='basic-page-picker'
                                    disabled={isFinalStep}
                                    placeholder='Select date'
                                    style={{ zIndex: -1, position: 'absolute' }}
                                    inputClass="input-date-style"
                                    format="ddd"
                                    multiple
                                    range
                                    buttons
                                    monthYearSeparator=' '
                                    weekStartDayIndex={1}
                                    showOtherDays
                                    fixMainPosition
                                    calendarPosition='bottom-start'
                                    offsetX={120}
                                    onChange={
                                      (dates: any) =>
                                        this.setDates(dates, (dates: string[]) => setFieldValue('timing.dates', dates))
                                    }
                                    onClose={() => this.setTimingDate(timingDate)}
                                  >
                                    <Box px={2}>
                                      <ButtonComponent>
                                        <Button
                                          data-test-id='save-date-btn'
                                          variant="contained"
                                          color="primary"
                                          onClick={this.calendarTimeRef.current?.closeCalendar}
                                          className="regButton"
                                          style={{
                                            ...styles.formButton,
                                            width: '100%'
                                          }}
                                        >
                                          Save
                                        </Button>
                                      </ButtonComponent>
                                    </Box>
                                  </DatePicker>
                                </Box>
                              </Box>
                              <Box
                                minWidth='48%'
                                {...stylesOuter.clickBlock}
                                style={{
                                  cursor: 'pointer',
                                  position: 'relative'
                                }}
                                onClick={(event: React.MouseEvent<HTMLImageElement>) => !isFinalStep && this.handleOpenDurationPopOver(event)}
                              >
                                <Box
                                  p={1}
                                  borderRadius='50%'
                                  bgcolor='#FEF3C7'
                                >
                                  <img
                                    data-test-id="add-timing-img"
                                    src={addTimingIcon}
                                    alt='map marker'
                                  />
                                </Box>
                                <Typography
                                  style={{
                                    ...styles.regularMiddle,
                                    ...styles.dotsOverflow,
                                    color: '#94A3B8',
                                    marginLeft: '16px'
                                  }}>
                                  {this.getDuration('Add timings')}
                                </Typography>
                                <Box
                                  p={1}
                                  className="buttonCursor"
                                  display='inline-block'
                                  mr='14px'
                                  position='absolute'
                                  right={0}
                                >
                                  <img
                                    data-test-id="timing-icon-btn"
                                    src={timerClock} alt='map marker'
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Popover
                              open={Boolean(this.state.anchorDuration)}
                              onClose={this.handleCloseDurationPopOver}
                              anchorEl={this.state.anchorDuration}
                              transformOrigin={{
                                vertical: -35,
                                horizontal: 'left',
                              }}
                              PaperProps={{
                                style: {
                                  paddingTop: '32px',
                                  border: "1px solid #FFFFFF",
                                  borderBottomRightRadius: '24px',
                                  boxShadow: '2px 3px 10px 1px rgba(0,0,0,0.35)'
                                }
                              }}
                            >
                              <Box mt={2} display={"flex"} justifyContent={"center"} alignItems={"center"} style={{ gap: 32 }}>
                                <Box
                                  data-test-id='duration-time-from'
                                  style={{
                                    cursor: 'pointer',
                                    color: this.getTimeColor({ isFromTime: this.state.isDurationFrom })
                                  }}
                                  onClick={() => this.setIsDurationFrom(true)}
                                >
                                  <span
                                    style={{
                                      fontFamily: 'Ubuntu',
                                      fontSize: 30
                                    }}>
                                    {durationFrom.hours}:{durationFrom.minutes}
                                  </span>
                                  <span
                                    style={{
                                      fontFamily: 'Ubuntu',
                                      fontSize: 16,
                                      marginLeft: 4
                                    }}>AM</span>
                                </Box>
                                <img src={timeRangeArrow} />
                                <Box
                                  data-test-id='duration-time-to'
                                  style={{
                                    cursor: 'pointer',
                                    color: this.getTimeColor({ isFromTime: !this.state.isDurationFrom })
                                  }}
                                  onClick={() => this.setIsDurationFrom(false)}
                                >
                                  <span
                                    style={{
                                      fontFamily: 'Ubuntu',
                                      fontSize: 30,
                                    }}>
                                    {durationTo.hours}:{durationTo.minutes}
                                  </span>
                                  <span
                                    style={{
                                      fontFamily: 'Ubuntu',
                                      fontSize: 16,
                                      marginLeft: 4
                                    }}>PM</span>
                                </Box>
                              </Box>
                              <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Box position={'relative'} display={"flex"} overflow={"hidden"} height={"142px"} alignItems="center" justifyContent={"center"}>
                                  <div className="duration-picker-container" style={{ position: "relative" }}>
                                    {/* There is a bug with initial timing and digits position, the init values set fixes it partially */}
                                    <DurationPicker
                                      data-test-id='duration-picker'
                                      maxHours={12}
                                      initialDuration={{
                                        hours: 10,
                                        minutes: 13,
                                        seconds: 0
                                      }}
                                      onChange={this.onDurationChange}
                                    />
                                  </div>
                                </Box>
                              </Box>
                              <Box py={2} px={4}>
                                <ButtonComponent>
                                  <Button
                                    data-test-id='save-date-btn'
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      this.handleCloseDurationPopOver();
                                      setFieldValue('timing.currentTiming', this.getCommonDuration())
                                    }}
                                    className="regButton"
                                    style={{
                                      ...styles.formButton,
                                      width: '100%'
                                    }}
                                  >
                                    Save
                                  </Button>
                                </ButtonComponent>
                              </Box>
                            </Popover>
                            <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                              <Checkbox
                                data-test-id='same-time-checkbox'
                                onClick={() => setFieldValue('timing.sameTiming', !values.timing.sameTiming)}
                                checked={values.timing.sameTiming}
                                disabled={isFinalStep}
                                className="sametimeAllDay"
                              />
                              <Typography
                                style={{
                                  fontWeight: 400,
                                  fontFamily: "Ubuntu",
                                  fontSize: 14,
                                  color: "#64748B"
                                }}
                              >
                                Same timings all days of week
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      }

                      {isImagesStep &&
                        <Box mt={3}>
                          {/* Add Images */}
                          <Box style={styles.boxDevider} display='flex' alignItems='center'>
                            <Typography style={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 700, marginLeft: '16px' }}>
                              ADD IMAGES
                            </Typography>
                          </Box>
                          <Box className="mainView"
                            style={{
                              backgroundColor: '#FFFFFF',
                              borderBottom: 'none',
                              marginTop: '24px',
                              borderRadius: '8px'
                            }}>
                            {/* Full/Empty images view */}
                            <Box pt={2} textAlign='center'>
                              {this.state.upldSPAImgLinks.length ?
                                <Box display='flex' justifyContent='space-around' flexWrap='wrap' mb={4}>
                                  {this.state.upldSPAImgLinks.map((imageLink: string | ArrayBuffer | null, index) =>
                                    <Box
                                      key={index}
                                      style={{
                                        position: 'relative',
                                        background: `url(${imageLink}) center/cover`,
                                        width: '30%',
                                        height: '10vw',
                                        borderRadius: '16px',
                                        marginTop: '4px'
                                      }}
                                    >
                                      <Box
                                        style={{
                                          position: 'absolute',
                                          top: '8px',
                                          right: '8px',
                                          borderRadius: '8px',
                                          cursor: 'pointer',
                                          backgroundColor: '#FFFFFF',
                                          padding: '4px'
                                        }}>
                                        <CloseIcon
                                          data-test-id='remove-spa-image'
                                          onClick={() => {
                                            if (!isFinalStep) {
                                              this.removeImgSpa(index);
                                              this.setImagesIdsToDelete(carouselImages?.[index].id);

                                              const spaImages = values.spaImages;
                                              spaImages.splice(index, 1);
                                              setFieldValue('spaImages', spaImages);
                                            }
                                          }}
                                          style={{
                                            color: '#DC2626'
                                          }} />
                                      </Box>
                                    </Box>
                                  )}
                                </Box> :
                                <>
                                  <Typography>
                                    No Spa has been added yet!
                                  </Typography>
                                  <img src={imgSpaEmpty} alt='pending time' style={{
                                    width: '50%',
                                    objectFit: 'cover',
                                    margin: '16px 0'
                                  }} />
                                </>}
                              {!isFinalStep && <Button
                                data-test-id='add-images-btn'
                                component='label'
                                variant="contained"
                                color="primary"
                                className="regButton"
                                style={{
                                  display: 'flex',
                                  background: 'var(--Primary, #D9EEEB)',
                                  color: '#047854',
                                  margin: 'auto',
                                  textTransform: 'none',
                                  width: '35%'
                                }}
                              >
                                <input
                                  multiple
                                  accept="image/*"
                                  data-test-id='spa-image-input'
                                  type="file"
                                  onChange={(event) => {
                                    const imageItems = Array.from(event.target.files || []);
                                    this.handleSpaImgUpload(imageItems);

                                    const spaImages = [...values.spaImages, ...imageItems];
                                    setFieldValue('spaImages', spaImages);
                                  }}
                                  className="avtarImg"
                                  style={{
                                    visibility: 'hidden',
                                    position: 'absolute'
                                  }}
                                />
                                <AddCircleOutlineIcon
                                  className="AddIconStyle" style={{
                                    color: '#398378',
                                    maxHeight: '22px'
                                  }} />
                                <Typography style={{
                                  marginLeft: '4px',
                                  fontWeight: 700
                                }}>
                                  Add Images
                                </Typography>
                              </Button>}
                            </Box>
                          </Box>
                        </Box>
                      }

                      <Box className="locationViewStyle" mt={6}>
                        <ButtonComponent>
                          <Button
                            data-test-id='back-btn'
                            variant="contained"
                            color="primary"
                            onClick={() => buttonProperties.cancelHandler()}
                            className="regButton"
                            style={{
                              background: 'var(--Primary, #D9EEEB)',
                              color: '#398378',
                              ...styles.formButton
                            }}
                            startIcon={buttonProperties.icon}
                          >
                            {buttonProperties.cancel}
                          </Button>
                          <Button
                            data-test-id='submit-btn'
                            variant="contained"
                            color="primary"
                            onClick={submitForm}
                            className="regButton"
                            style={styles.formButton}
                          >
                            {this.state.loading ? <CircularProgress style={{ color: '#FFFFFF' }} /> : buttonProperties.submit}
                          </Button>
                        </ButtonComponent>
                      </Box>
                    </>
                  )
                }}
              </Formik>

            </Box>
            {/* footer */}
            <AppFooter navigation={this.props.navigation} />
          </Box>
        </ TypographyView>
      </RegistrationFormView>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
