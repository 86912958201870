import { Box, Button, InputLabel, Modal, TextField, Typography, styled } from '@material-ui/core';
import React, { Component, DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { getStars, getTranslationConfig } from './helpers';
import CloseIcon from '@material-ui/icons/Close';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { clodUploadImg, imgArrowRightViewMore } from './assets';
export const configJSON = require("./config");

const TypographyView = styled(Typography)({
    'input': {
        '&::placeholder': {
            color: '#94A3B8'
        }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E0E3E7'
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        "& fieldset": {
            borderColor: "#E0E3E7" // this is the original border color
        },
        "&:hover fieldset": {
            borderColor: "#E0E3E7" // use the original border color on hover
        }
    },
    '& .businessLicenseView': {
        position: 'relative',
        border: '2px',
        borderColor: '#CBD5E1',
        borderStyle: 'dashed',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '16px',
        padding: '6px 20px',
        flexDirection: 'column', display: 'flex',
        marginTop: '8px',
        cursor: 'pointer'
    },
    '& .uploadImg': {
        width: '36px',
        alignSelf: 'center'
    },
    '& .txtErrorMsg': {
        color: '#DC2626',
        fontSize: '12px',
        paddingTop: '5px'
    },
    '& .inputStyle': {
        borderRadius: '8px',
        background: 'var(--Basic-White, #FFF)',
    },
    '& .agreeButton': {
        display: 'flex',
        width: '45%',
        height: '56px',
        padding: '16px 36.5px',
        borderRadius: '8px',
        background: 'var(--Primary, #398378)',
        fontFamily: 'Ubuntu',
        textTransform: 'none'
    },
    '& .declineButton': {
        display: 'flex',
        width: '45%',
        height: '56px',
        padding: '16px 36.5px',
        borderRadius: '8px',
        background: 'var(--Primary, #D9EEEB)',
        color: '#398378',
        fontFamily: 'Ubuntu',
        textTransform: 'none'
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '5px'
        },
        '*::-webkit-scrollbar-track': {
            backgroundColor: '#D9EEEB',
            borderRadius: '4px'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#398378',
            outline: '1px solid slategrey',
            borderRadius: '4px'
        },
        'p': {
            fontFamily: 'Ubuntu'
        },
        'label': {
            fontFamily: 'Ubuntu'
        }
    }
});

export interface Props {
    getUserReviews: () => void,
    pathParam: string,
    createUserReview: (arg: any) => void,
    gotoLogin: () => void,
    reviewsList: {
        id: number;
        attributes: {
            average_review: number,
            average_review_string: string,
            rating: number,
            comment: string,
            created_at: string,
            review_photos: {
                id: string,
                url: string,
                media_type: string
            }[],
            account: {
                attributes: {
                    first_name: string,
                    full_name: string
                }
            }
        }
        name: string;
        comment: string;
        created_at: string
    }[];
    goToReviews?: () => void,
    showCreateReviewModal: boolean,
    setShowCreateReviewModal: (value: boolean) => void
}

interface S {
    clickedStartIndex: number;
    formData: {
        rating: number;
        comment: string;
        files: File[];
        ratingError?: boolean;
        commentError?: boolean;
    },
    reviewImages: (string | ArrayBuffer | null)[],
    addOtherFileMode: boolean,
    contentPromptSrc: {
        url: string,
        mediaType: string
    }
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ReviewsController extends Component<Props, S, SS> {
    constructor(props: Props) {
        super(props);

        this.state = {
            clickedStartIndex: 0,
            formData: {
                rating: 0,
                comment: '',
                files: [],
                ratingError: false,
                commentError: false
            },
            reviewImages: [],
            addOtherFileMode: false,
            contentPromptSrc: {
                url: '',
                mediaType: ''
            }
        };
        this.setComment = this.setComment.bind(this);
    }

    async componentDidMount() {
        this.props.getUserReviews();
    };

    getFormDataFromPayload = (APIPayload: Record<string, any>) => {
        const formData = new FormData();

        for (let apiKey in APIPayload) {
            const entity = APIPayload[apiKey];
            if (Array.isArray(entity)) {
                entity.forEach((entityItem) => formData.append(apiKey, entityItem, entityItem.name))
            } else if (entity) {
                formData.append(apiKey, String(entity));
            }
        }
        return formData
    }

    onSubmit(event: { preventDefault: () => void }) {
        event.preventDefault()
        const { formData } = this.state;
        const { formData: {
            comment,
            rating,
            files
        } } = this.state;

        if (comment && rating) {
            const APIPayload = {
                'spa_id': this.props.pathParam,
                'rating': rating,
                'comment': comment,
                'review_photos[]': files
            }
            this.props.createUserReview(this.getFormDataFromPayload(APIPayload));
            // Put the result to request
            this.setShowCreateReviewModalHandler(false)
        } else {
            const formDataErrors = {
                ...formData,
                commentError: !comment,
                ratingError: !rating
            }
            this.setState({ formData: formDataErrors });
        }
    }

    handleReviewImgUpload = (event: any) => {
        const reviewImage = event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                files: [...(this.state.formData.files), reviewImage]
            },
            addOtherFileMode: true
        });
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
            this.setState({ reviewImages: [...this.state.reviewImages, fileReader.result] });
        });
        fileReader.readAsDataURL(reviewImage);
    };

    setClickedStarIndex(index: number) {
        this.setState({ clickedStartIndex: index });
    }

    setComment(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        this.setState({
            formData: {
                ...this.state.formData,
                comment: event.target.value,
                commentError: false
            }
        })
    }

    setRating(rating: number) {
        this.setState({
            formData: {
                ...this.state.formData,
                rating,
                ratingError: false
            }
        })
    }

    onAddButtonClick() {
        this.setState({ addOtherFileMode: false });
    }

    setShowCreateReviewModalHandler(value: boolean) {
        !value && this.setState({
            reviewImages: [],
            formData: {
                rating: 0,
                comment: '',
                files: []
            },
            addOtherFileMode: false,
            clickedStartIndex: 0
        })
        this.props.setShowCreateReviewModal(value);
        this.props.gotoLogin();
    }

    setContentPromptSrc(contentPromptSrc: { url: string, mediaType: string }) {
        this.setState({ contentPromptSrc });
    }

    removeFile(index: number) {
        const actFormData = this.state.formData;
        const currentFiles = actFormData.files;
        const reviewImagesCopy = this.state.reviewImages;
        currentFiles.splice(index, 1);
        reviewImagesCopy.splice(index, 1);
        this.setState({
            formData: {
                ...actFormData,
                files: currentFiles
            },
            reviewImages: reviewImagesCopy
        }, () => {
            !currentFiles.length && this.setState({ addOtherFileMode: false })
        })
    }

    render() {
        const { t, dir, isArabic } = getTranslationConfig();
        const uploadedFiles = this.state.formData.files;
        const reviewsList = this.props.reviewsList;
        const {
            average_review: avgReview,
            average_review_string: avgReviewString
        } = reviewsList?.[0]?.attributes || {};

        const isEmbedded = Boolean(this.props.goToReviews);
        const resultReviewsList = isEmbedded ? reviewsList.slice(0, 3) : reviewsList;

        return (
            <TypographyView dir={dir}>
                <Box>
                    <Box>
                        <Box display='flex'>
                            <Typography
                                style={{
                                    fontSize: '32px',
                                    color: '#000000',
                                    paddingRight: '8px',
                                }}>
                                {avgReview?.toFixed(1)}
                            </Typography>
                            {/* stars */}
                            <Box
                                display='flex'
                                alignItems='center'
                            >
                                {getStars(avgReview)}
                            </Box>
                        </Box>
                        <Typography
                            style={{
                                color: '#64748B'
                            }}
                        >
                            {avgReviewString}
                        </Typography>
                    </Box>
                </Box>

                <Box mt={6} style={{
                    ...(!isEmbedded && {
                        height: '80vh',
                        overflow: 'auto'
                    })
                }}>
                    {resultReviewsList.map((reviewItem, index) => {
                        const accountAttributes = reviewItem.attributes?.account?.attributes;

                        if (!accountAttributes) {
                            return null;
                        }

                        const {
                            first_name: name,
                            full_name: fullName
                        } = accountAttributes;

                        const {
                            comment,
                            rating,
                            created_at: createdAt,
                            review_photos: reviewPhotos
                        } = reviewItem.attributes;
                        return <Box key={reviewItem.id} style={{ ...styles.serviceBlock, ...(!index && { marginTop: 0 }) }}>
                            <Box display='flex' alignItems='center'>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 700,
                                        color: '#000000'
                                    }}
                                >
                                    {name || fullName}
                                </Typography>
                                {/* stars */}
                                <Box display='flex' >
                                    {getStars(rating)}
                                </Box>
                            </Box>
                            <Typography
                                style={{
                                    paddingTop: '16px',
                                    fontSize: '14px',
                                    color: '#0F172A'
                                }}
                            >
                                {new Date(createdAt).toLocaleString(
                                    isArabic ? 'ar-eg' : 'default',
                                    {
                                        month: 'long',
                                        day: 'numeric',
                                        year: 'numeric'
                                    })}
                            </Typography>
                            <Typography
                                style={{
                                    paddingTop: '12px',
                                    fontSize: '14px',
                                    color: '#334155'
                                }}
                            >
                                {comment}
                            </Typography>
                            <Box pt={2} display='flex' gridGap={8}>
                                {reviewPhotos?.map((revImage) => {
                                    const { id, url, media_type: mediaType } = revImage;
                                    const srcObj = { url, mediaType };
                                    return <Box key={id}>
                                        {mediaType.includes('image/') ?
                                            <img
                                                data-test-id='review-image'
                                                onClick={() => this.setContentPromptSrc(srcObj)}
                                                src={url}
                                                alt='review-image'
                                                style={styles.reviewThumbnail as DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>}
                                            /> :
                                            <Box position='relative'>
                                                <PlayCircleOutlineIcon
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        color: '#B6E6DF',
                                                        height: '28%',
                                                        width: '28%',
                                                    }} />
                                                <video
                                                    data-test-id='review-video'
                                                    onClick={() => this.setContentPromptSrc(srcObj)}
                                                    style={styles.reviewThumbnail as DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>}
                                                    preload="metadata"
                                                >
                                                    <source src={url} type="video/mp4" />
                                                </video>
                                            </Box>
                                        }
                                    </Box>
                                })}
                            </Box>
                        </Box>
                    })}
                </Box>

                <Modal
                    data-test-id='review-img-modal'
                    open={Boolean(this.state.contentPromptSrc.url)}
                    onClose={() => this.setContentPromptSrc({ url: '', mediaType: '' })}
                >
                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: '#FFFFFF',
                            borderRadius: '8px 8px 32px 8px',
                            outline: 'none',
                            padding: '16px'
                        }}
                    >
                        <CloseIcon
                            data-test-id='review-modal-close'
                            onClick={() => this.setContentPromptSrc({ url: '', mediaType: '' })}
                            style={{
                                cursor: 'pointer',
                                marginLeft: isArabic ? 0 : 'auto',
                                display: 'block',
                                paddingBottom: '8px',
                            }} />
                        {this.state.contentPromptSrc.mediaType.includes('image/') ?
                            <img src={this.state.contentPromptSrc.url} alt='full image' style={styles.modalPicture} /> :
                            <video controls style={styles.modalPicture}>
                                <source src={this.state.contentPromptSrc.url} type="video/webm" />
                            </video>}
                    </Box>
                </Modal>

                <Box mt={5} mb={5}>
                    {isEmbedded && <Box my={3}>
                        <Button
                            variant='text'
                            style={{
                                fontSize: '16px',
                                fontWeight: 700,
                                color: '#398378',
                                padding: '10px 16px',
                                backgroundColor: '#F1F5F9'
                            }}
                            endIcon={
                                <img
                                    src={imgArrowRightViewMore}
                                    alt='arrow-right'
                                    style={{
                                        marginRight: '8px',
                                        ...(isArabic && { transform: 'rotate(180deg)' })
                                    }}
                                />
                            }
                            onClick={() => this.props.goToReviews?.()}
                        >
                            {t['landing-page-reviews-view-more']}
                        </Button>
                    </Box>}
                    <Button
                        data-test-id='open-review'
                        style={{
                            padding: '10px 16px',
                            fontSize: '16px',
                            color: '#fff',
                            borderRadius: '8px',
                            backgroundColor: '#398378',
                            fontWeight: 700,
                            textTransform: 'none'
                        }}
                        onClick={() => this.setShowCreateReviewModalHandler(true)}
                    >
                        {t['landing-page-reviews-write-review']}
                    </Button>
                    <Modal
                        dir={dir}
                        data-test-id='review-modal'
                        open={this.props.showCreateReviewModal}
                        onClose={() => this.setShowCreateReviewModalHandler(false)}
                    >
                        <TypographyView>
                            <form
                                data-test-id='review-form'
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '45%',
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '8px 8px 32px 8px',
                                    outline: 'none'
                                }}
                                onSubmit={(event) => this.onSubmit(event)}
                            >
                                <Box p={3} pl={5} borderBottom='1px solid #E2E8F0' display='flex' justifyContent='space-between' alignItems='center'>
                                    <Typography
                                        style={{
                                            fontSize: '24px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {t['reviews-modal-title']}
                                    </Typography>
                                    <CloseIcon
                                        data-test-id='review-close-btn'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.setShowCreateReviewModalHandler(false)}
                                    />
                                </Box>
                                <Box px={5} py={2} borderBottom='1px solid #E2E8F0'>
                                    <Typography
                                        style={{
                                            color: '#334155',
                                            fontSize: '16px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {t['reviews-modal-rate-text']}
                                    </Typography>
                                    {this.state.formData.ratingError &&
                                        <Typography className="txtErrorMsg">
                                            {configJSON.spaRatingErrorMsg}
                                        </Typography>}
                                    <Box py={2} width='55%' display='flex' justifyContent='space-between'>
                                        {Array.from(Array(5)).map(
                                            (iconNumber, index) =>
                                                index < this.state.clickedStartIndex ? <StarIcon
                                                    data-test-id='star-icon'
                                                    onClick={() => {
                                                        this.setClickedStarIndex(index);
                                                        this.setRating(index)
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#F59E0B'
                                                    }} /> :
                                                    <StarBorderIcon
                                                        data-test-id='star-bordered-icon'
                                                        onClick={() => {
                                                            this.setClickedStarIndex(index + 1);
                                                            this.setRating(index + 1)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                        )}
                                    </Box>
                                    <Typography style={{
                                        color: '#334155',
                                        fontWeight: 700,
                                        paddingBottom: '2px'
                                    }} >
                                        {t['reviews-modal-add-photo-text']}
                                    </Typography>

                                    <Box
                                        maxHeight='20vh'
                                        style={{
                                            overflow: 'auto',
                                            margin: '0 -3px'
                                        }}>
                                        {uploadedFiles?.map((file, index) => {
                                            const picSRC = String(this.state.reviewImages[index]);
                                            return <Box
                                                display='flex'
                                                justifyContent='flex-start'
                                                gridGap={8}
                                                boxShadow='rgba(0, 0, 0, 0.04) 0px 3px 2px 1px'
                                                p={1}
                                                mt={1}
                                                mx='3px'
                                                mb='4px'
                                                borderRadius={8}
                                            >
                                                <Box
                                                    position='relative'
                                                    height={50}
                                                    width={59}
                                                >
                                                    {picSRC.includes('video') ?
                                                        <>
                                                            <PlayCircleOutlineIcon
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                    color: '#B6E6DF',
                                                                    height: '50%',
                                                                    width: '50%',
                                                                }} />
                                                            <video
                                                                style={styles.imgIcon as DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>}
                                                                preload="metadata"
                                                            >
                                                                <source src={picSRC} type="video/mp4" />
                                                            </video>
                                                        </>
                                                        :
                                                        <img
                                                            src={String(this.state.reviewImages[index])}
                                                            alt='venue-avatar'
                                                            style={styles.imgIcon as DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>} />}
                                                </Box>

                                                <Box
                                                    display='flex'
                                                    justifyContent='space-between'
                                                    alignItems='center'
                                                    width='100%'>
                                                    <Box>
                                                        <Typography
                                                            style={{}}
                                                        >
                                                            {file.name}
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                color: '#64748B'
                                                            }}>
                                                            {`${(file.size / 1000).toFixed(2)} KB`}
                                                        </Typography>
                                                    </Box>
                                                    <CloseIcon
                                                        style={{
                                                            color: '#64748B',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => this.removeFile(index)}
                                                    />
                                                </Box>
                                            </Box>
                                        })}
                                    </Box>
                                    {this.state.addOtherFileMode ?
                                        <Button
                                            data-test-id='add-image-button'
                                            className="locationView"
                                            style={{
                                                marginTop: '16px',
                                                marginLeft: 'auto',
                                                backgroundColor: '#D9EEEB',
                                                textTransform: 'none',
                                                padding: '4px 8px',
                                                display: 'flex'
                                            }}
                                            onClick={() => this.onAddButtonClick()}
                                        >
                                            <AddCircleOutlineIcon className="AddIconStyle" style={{
                                                color: '#398378',
                                                maxHeight: '22px'
                                            }} />
                                            <Typography
                                                className="addOther"
                                                style={{
                                                    color: '#398378',
                                                    fontSize: '12px',
                                                    fontWeight: 700,
                                                    marginLeft: '4px'
                                                }}
                                            >
                                                {t['reviews-modal-add-other']}
                                            </Typography>
                                        </Button> :
                                        <Box className="businessLicenseView">
                                            <img src={clodUploadImg} className="uploadImg" style={{ margin: '10px 0' }} />
                                            <Typography style={{
                                                color: '#94A3B8',
                                                paddingBottom: '16px'
                                            }} >{t['reviews-modal-add-photo-description-text']}</Typography>
                                            <Button
                                                component='label'
                                                style={{
                                                    textTransform: 'none',
                                                    margin: 'auto',
                                                    width: '100%',
                                                    height: '100%',
                                                    position: 'absolute'
                                                }}>
                                                <input
                                                    data-test-id='file-input'
                                                    accept="image/*, video/*"
                                                    type="file"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleReviewImgUpload(event)}
                                                    style={{
                                                        visibility: 'hidden',
                                                        position: 'absolute'
                                                    }}
                                                />
                                            </Button>
                                        </Box>
                                    }

                                    <Box pb={2}>
                                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                            <InputLabel className="testLabel" style={{
                                                color: '#334155',
                                                fontWeight: 700,
                                                paddingBottom: '4px'
                                            }}>{t['reviews-modal-review-text']}</InputLabel>
                                        </Box>
                                        <TextField
                                            fullWidth={true}
                                            value={this.state.formData.comment}
                                            onChange={this.setComment}
                                            variant="outlined"
                                            className="inputStyle"
                                            data-test-id="txtspaSummary"
                                            placeholder={t['reviews-modal-review-description-text']}
                                            error={this.state.formData.commentError}
                                            multiline={true}
                                            size='medium'
                                            inputProps={{
                                                style: {
                                                    height: "80px"
                                                },
                                            }}
                                        />
                                        {this.state.formData.commentError &&
                                            <Typography className="txtErrorMsg">
                                                {configJSON.spaSummaryErrorMsg}
                                            </Typography>}
                                    </Box>
                                </Box>

                                <Box p={3}>
                                    <Box
                                        display='flex'
                                        justifyContent='space-between'
                                        width='50%'
                                        marginLeft='auto'>
                                        <Button
                                            data-test-id='cancel-btn'
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.setShowCreateReviewModalHandler(false)}
                                            className="declineButton"
                                        >
                                            {t['reviews-modal-cancel']}
                                        </Button>
                                        <Button
                                            data-test-id={"btnAcceptTerms"}
                                            type='submit'
                                            variant="contained"
                                            color="primary"
                                            className="agreeButton"
                                        >
                                            {t['reviews-modal-cancel']}
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </TypographyView>
                    </Modal>
                </Box>
            </TypographyView>
        )
    }
}

const styles = {
    serviceBlock: {
        marginTop: '20px',
        backgroundColor: '#ffffff',
        padding: '16px'
    },
    backButton: {
        color: '#0F172A',
        cursor: 'pointer'
    },
    reviewThumbnail: {
        width: '100px',
        height: '100px',
        objectFit: 'cover',
        border: '2px solid #CBD5E1',
        cursor: 'pointer'
    },
    modalPicture: {
        maxWidth: '80vw',
        maxHeight: '90vh'
    },
    imgIcon: {
        display: 'block',
        objectFit: 'cover',
        borderRadius: '8px',
        width: '100%',
        height: '100%'
    }
};
