import React from 'react'
import {
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { getTransContent, getTranslationConfig } from './helpers';

export type Product = {
  id: number;
  image_url: string;
  name: string;
  arabic_name?: string;
  shortDescription?: string;
  date?: string;
  time?: string;
};

export const GalleryBlock = ({
  products,
  narrowImages,
  buttons,
}: {
  products: Product[],
  narrowImages?: boolean,
  buttons: {
    title: string,
    handler: (arg: number) => void
  }[]
}) => (
  <Box display='flex' flexWrap='wrap' justifyContent='space-around' gridGap={16}>
    {products.map((product) => {
      const { name } = getTransContent(product);
      const { isArabic } = getTranslationConfig()

      const resultContentStyle = isArabic ? { right: 20 } : { left: 20 };

      return <Box
        style={{
          position: 'relative',
          borderRadius: '8px',
          width: '32%',
          height: 'fit-content',
        }}>
        <img
          style={{
            width: '100%',
            height: narrowImages ? '18rem' : '24vw',
            objectFit: 'cover',
            borderRadius: '8px'
          }}
          src={product.image_url}
        />
        <Box position='absolute' {...resultContentStyle} bottom={20}>
          <Box mb='5px' mt={3}>
            <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 24,
                color: '#ffffffff',
                paddingBottom: '10px'
              }}
            >{name}</Typography>
            {product.shortDescription && <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 16,
                color: '#ffffffff',
              }}
            >{product.shortDescription}</Typography>}
            {product.date && <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 16,
                color: '#ffffffff',
              }}
            >{product.date}</Typography>}
            {product.time && <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: 16,
                color: '#ffffffff',
                paddingBottom: '24px'
              }}
            >{product.time}</Typography>}
          </Box>
          {buttons.map((button, index) => <Button
            style={{
              padding: '10px',
              borderRadius: 8,
              backgroundColor: index ? '#398378ff' : '#e8f6f4ff',
              color: index ? '#ffffff' : '#398378ff',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              textTransform: 'none',
              marginRight: '16px'
            }}
            onClick={() => button.handler(product.id)}
          >
            {button.title}
          </Button>)}
        </Box>
      </Box>
    }
    )}
  </Box>
)
