import React from "react";
import { Typography } from "@material-ui/core";

interface ErrorProps {
    error: string;
    visible: boolean;
}

export const ErrorComponent: React.FC<ErrorProps> = ({ error, visible }) => {
    if (!visible) return null;

    return (
        <Typography
            style={{
                backgroundColor: "#FEE2E2",
                color: "#DC2626",
                fontFamily: "Ubuntu",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                borderLeft: "4px solid #DC2626",
                paddingLeft: "12px",
                marginTop: "20px",
                padding: "12px",
            }}
        >
            {error}
        </Typography>
    );
};