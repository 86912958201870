import {
    Box,
    ClickAwayListener,
    IconButton,
    InputAdornment,
    InputLabel,
    Modal,
    Popper,
    Snackbar,
    TextField,
    Typography,
    styled,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import UserProfileBasicController, {
    Props,
} from '../UserProfileBasicController';
import { TypographyView } from '../../../ordermanagement1/src/styledFont';
import { DeleteButton, LogoutButton } from '../CutomerSettings.web';
import { getStorageData } from '../../../../../packages/framework/src/Utilities';
import { getFormDataFromPayload } from '../../../../blocks/utilities/src/handle-form';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { UpdateType } from '../SpaOwnerSettings.web';
import { getTranslationConfig } from '../../../../components/src/helpers';

const CustomInputLabel = styled(InputLabel)({
    fontFamily: 'Ubuntu',
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: '16px',
    color: '#334155',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '-9px',
});

const CustomTextField = styled(TextField)({
    marginBottom: '30px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#CBD5E1',
        },
        '&:hover fieldset': {
            borderColor: '#CBD5E1',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#CBD5E1',
        },
    },
    '& .MuiOutlinedInput-input': {
        fontFamily: 'Ubuntu',
        fontSize: '18px',
        padding: '12px 14px',
    },
    '& .MuiFormHelperText-root': {
        color: '#EF4444',
        marginTop: '5px',
        marginBottom: '5px',
        fontSize: '14px',
        background: 'none',
        padding: '0',
    },
});

const styles = {
    infoIcon: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px',
        color: '#34D399',
        marginRight: '4px'
    },
    listBox: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        padding: '2px'
    },
    listItem: {
        color: '#000000',
        fontSize: '12px'
    }
};

export interface StateType {
    newValue: string;
    confirmValue: string;
    emailError: string | null;
    passwordError: string | null;
    phoneError: string | null;
}

export default class UpdateDetailsModal extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    handleCloseModal = () => {
        if (this.props.setOpenUpdateModal) {
            this.setState({
                emailError: null,
                emailConfirmError: null,
                passwordError: null,
                phoneError: null,
                newValue: '',
                confirmValue: '',
                newEmail: this.state.email,
            }, () => {
                this.forceUpdate();
            });
            this.props.setOpenUpdateModal(false);
        }
    };

    handleInputChange = (name: keyof StateType, value: string, t: Record<string, string>) => {
        const emailError = this.validateEmailId(value.trim()) ? null : t['profile-settings-invalid-email'];

        if (name === 'newValue') {
            this.setState({
                newValue: value,
                emailError,
            });
        } else if (name === 'confirmValue') {
            const emailConfirmError = value === this.state.newValue ? emailError : t['profile-settings-emails-do-not-match'];
            this.setState({
                confirmValue: value,
                emailConfirmError,
            });
        } else {
            this.setState({
                [name]: value,
            } as Pick<StateType, keyof StateType>);
        }
    };


    handleClickShowPassword = () => {
        this.setState((prevState) => ({
            enablePasswordField: !prevState.enablePasswordField,
        }));
    };

    handleClickConfirmShowPassword = () => {
        this.setState((prevState) => ({
            enableReTypePasswordField: !prevState.enableReTypePasswordField,
        }));
    };

    handleUpdate = async (t: Record<string, string>) => {
        const { newValue, confirmValue } = this.state;
        const { updateType } = this.props;

        if (newValue !== confirmValue) {
            this.setState(
                {
                    showSnack: true,
                    snackMessage: t['profile-settings-modal-snack-values-not-matched'],
                },
                this.handleCloseModal
            );
            return;
        }

        try {
            const user_id = await getStorageData('user_id');
            let APIPayload = {};

            if (updateType === UpdateType.email) {
                if (!this.validateEmailId(newValue)) return;
                APIPayload = {
                    'account[email]': newValue,
                    'account[profile][account_id]': user_id,
                };
            } else if (updateType === UpdateType.phone) {
                if (!newValue) return;
                APIPayload = {
                    'account[phone_number]': newValue,
                    'account[profile][account_id]': user_id,
                };
            } else if (updateType === UpdateType.password) {
                if (!this.validateUserPassword(newValue, t)) return;
                APIPayload = {
                    'account[password]': newValue,
                    'account[profile][account_id]': user_id,
                };
            }

            await this.updatProfileApiCall(getFormDataFromPayload(APIPayload));

            switch (updateType) {
                case UpdateType.email:
                    this.handleEmailUpdate(newValue);
                    break;
                case UpdateType.phone:
                    this.handlePhoneNumberUpdate(newValue)
                    break;
            }

            const successMessage = t[`profile-settings-${updateType}-updated`];
            this.setState(
                {
                    showSnack: true,
                    snackMessage: successMessage,
                },
                () => {
                    this.handleCloseModal();
                }
            );
        } catch (error) {
            this.setState({
                showSnack: true,
                snackMessage: t[`profile-settings-update-${updateType}-failed`],
            });
        }
    };

    // Handle errors for main and confirmation inputs
    handleError = (confirm?: UpdateType) => {
        switch (confirm || this.props.updateType) {
            case UpdateType.email:
                return this.state.emailError;
            case confirm:
                return this.state.emailConfirmError;
            case UpdateType.phone:
                return this.state.phoneError;
            case UpdateType.password:
                return this.state.passwordError;
        }
    }

    selectUpdateName = (t: Record<string, string>) => {
        const updType = this.props.updateType;

        const email = updType === UpdateType.email && t['profile-settings-change-email'];
        const phone = updType === UpdateType.phone && t['profile-settings-change-phone'];
        const passsword = updType === UpdateType.password && t['profile-settings-change-password'];

        return email || phone || passsword
    }

    getInputType = (updType?: UpdateType) => {
        const email = updType === UpdateType.email && 'text';
        const phone = updType === UpdateType.phone && 'number';
        const passsword = updType === UpdateType.password && this.getTernaryValue(this.state.enablePasswordField, 'password', 'text');
        const confPasssword = updType === UpdateType.passwordConfirm && this.getTernaryValue(this.state.enableReTypePasswordField, 'password', 'text');


        return email || phone || passsword || confPasssword || ''
    }

    render() {
        const { t, dir, isArabic } = getTranslationConfig();
        const { closeIconMargin } = this.getArabicValues(isArabic);

        const { openUpdateModal, updateType } = this.props;
        const { showSnack, snackMessage } = this.state;

        const isError =
            this.getEitherLogicalValue(
                snackMessage.includes(t['profile-settings-not-matched-sentence']),
                snackMessage.includes(t['profile-settings-failed-sentence'])
            );

        return (
            <>
                <Modal
                    dir={dir}
                    data-test-id='update-modal'
                    open={Boolean(openUpdateModal)}
                    onClose={this.handleCloseModal}
                >
                    <TypographyView>
                        <Box
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: '45%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '8px 8px 32px 8px',
                                outline: 'none',
                                maxWidth: '80%',
                                minWidth: '20rem',
                                height: 'auto',
                            }}
                        >
                            <Box p={2} borderBottom='1px solid #E2E8F0'>
                                <Box
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='center'
                                >
                                    <CloseIcon
                                        data-test-id='update-close-btn'
                                        style={{
                                            cursor: 'pointer',
                                            ...closeIconMargin
                                        }}
                                        onClick={this.handleCloseModal}
                                    />
                                </Box>
                                <Box px={5} py={1}>
                                    <Typography
                                        style={{
                                            fontSize: '24px',
                                            fontWeight: 700,
                                            marginLeft: '-15px',
                                        }}
                                    >
                                        {this.selectUpdateName(t)}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box borderBottom='1px solid #E2E8F0'>
                                <Box px={5}>
                                    <Box display='flex' alignItems='baseline' gridGap={8}>
                                        <CustomInputLabel style={{ marginTop: '20px' }}>
                                            {t[`profile-settings-modal-${updateType}`]}
                                            <span style={{ color: 'red', paddingLeft: '5px' }}>
                                                {'*'}
                                            </span>
                                        </CustomInputLabel>
                                        {updateType === UpdateType.password &&
                                            <ClickAwayListener
                                                data-test-id='click-away'
                                                onClickAway={this.handleClosePopover}>
                                                <InfoOutlinedIcon
                                                    data-test-id='pass-info-icon'
                                                    className="infoIcon"
                                                    aria-describedby={'pop-over'}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#398378',
                                                        width: '20px',
                                                        position: 'relative',
                                                        top: '6px'
                                                    }}
                                                    onClick={this.handlePopover}
                                                />
                                            </ClickAwayListener>
                                        }
                                        <Popper
                                            id='info-popover'
                                            open={this.state.openInfoPopover}
                                            anchorEl={this.state.anchorInfoEl}
                                            placement='right-start'
                                            style={{
                                                borderRadius: '8px',
                                                left: '16px',
                                                top: '-8px',
                                                zIndex: 10000
                                            }}
                                        >
                                            <Box p={2} style={{
                                                background: '#D9EEEB',
                                                borderRadius: '8px',
                                                marginLeft: '8px',
                                                marginTop: '-4px',
                                                padding: '12px'
                                            }}>
                                                <TypographyView>
                                                    <Box sx={styles.listBox}>
                                                        <CheckCircleIcon style={styles.infoIcon as React.CSSProperties} />
                                                        <Typography style={styles.listItem} >
                                                            {t['profile-settings-password-validation-capital']}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={styles.listBox}>
                                                        <CheckCircleIcon style={styles.infoIcon as React.CSSProperties} />
                                                        <Typography style={styles.listItem} >
                                                            {t['profile-settings-password-validation-lowercase']}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={styles.listBox}>
                                                        <CheckCircleIcon style={styles.infoIcon as React.CSSProperties} />
                                                        <Typography style={styles.listItem} >
                                                            {t['profile-settings-password-validation-number']}
                                                        </Typography>
                                                    </Box>
                                                </TypographyView>
                                            </Box>
                                        </Popper>
                                    </Box>
                                    <CustomTextField
                                        data-test-id='new-value'
                                        variant='outlined'
                                        fullWidth
                                        margin='normal'
                                        type={this.getInputType(updateType)}
                                        value={this.state.newValue}
                                        onChange={(e) =>
                                            this.handleInputChange('newValue', e.target.value, t)
                                        }
                                        error={!!this.handleError()}
                                        helperText={this.handleError()}
                                        InputProps={{
                                            endAdornment: updateType === UpdateType.password && (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={this.handleClickShowPassword}
                                                        edge='end'
                                                    >
                                                        {this.state.enablePasswordField ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Box>
                                        <CustomInputLabel style={{ marginTop: '2px' }}>
                                            {t[`profile-settings-modal-${updateType}-confirm`]}
                                            <span style={{ color: 'red', paddingLeft: '5px' }}>
                                                {'*'}
                                            </span>
                                        </CustomInputLabel>
                                        <CustomTextField
                                            data-test-id='confirm-value'
                                            variant='outlined'
                                            fullWidth
                                            margin='normal'
                                            type={this.getInputType(updateType === UpdateType.password ? UpdateType.passwordConfirm : updateType)}
                                            value={this.state.confirmValue}
                                            onChange={(e) =>
                                                this.handleInputChange('confirmValue', e.target.value, t)
                                            }
                                            error={!!this.handleError(updateType === UpdateType.email ? UpdateType.emailConfirm : undefined)}
                                            helperText={this.handleError(updateType === UpdateType.email ? UpdateType.emailConfirm : undefined)}
                                            InputProps={{
                                                endAdornment: updateType === UpdateType.password && (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='toggle password visibility'
                                                            onClick={this.handleClickConfirmShowPassword}
                                                            edge='end'
                                                        >
                                                            {this.state.enableReTypePasswordField ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box p={3}>
                                <Box
                                    dir='ltr'
                                    display='flex'
                                    justifyContent='flex-end'
                                >
                                    <LogoutButton
                                        data-test-id='update-cancel-btn'
                                        variant='contained'
                                        color='primary'
                                        style={{ marginRight: '10px', width: '7rem' }}
                                        onClick={this.handleCloseModal}
                                    >
                                        {t['profile-settings-cancel']}
                                    </LogoutButton>
                                    <DeleteButton
                                        data-test-id='update-confirm-btn'
                                        variant='contained'
                                        color='primary'
                                        style={{ marginRight: '10px', width: '7rem' }}
                                        onClick={() => this.handleUpdate(t)}
                                    >
                                        {t['profile-settings-done']}
                                    </DeleteButton>
                                </Box>
                            </Box>
                        </Box>
                    </TypographyView>
                </Modal>
                <Snackbar
                    dir={dir}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={showSnack}
                    onClose={() => this.setState({ showSnack: false })}
                    style={{
                        backgroundColor: '#FFFFFF',
                        color: '#0F172A',
                        borderRadius: '8px',
                        padding: '8px 16px',
                        boxShadow: '0px 6px 15px -3px #00000026',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isError ? (
                            <CancelIcon
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    color: '#EF4444',
                                    marginRight: '8px',
                                }}
                            />
                        ) : (
                            <CheckCircleIcon
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    color: '#34D399',
                                    marginRight: '8px',
                                }}
                            />
                        )}
                        <Typography>{snackMessage}</Typography>
                    </div>
                </Snackbar>
            </>
        );
    }
}
