import React from "react";
import ReviewsController, { Props } from "./ReviewsController";
import { Box, Typography, styled } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import ReviewsView from '../../../components/src/Reviews.web';
import { getTranslationConfig } from '../../../components/src/helpers';

export default class Reviews extends ReviewsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { t, dir, isArabic } = getTranslationConfig();

    return (
      <Box style={{ backgroundColor: '#e8f6f4ff' }}>
        {/* header */}
        <AppHeader forceUpdate={this.forceUpdate.bind(this)} />
        {/* page content */}
        <Box
          dir={dir}
          py={3}
          px='140px'
        >
          <ArrowBackIcon
            data-test-id='arrow-back'
            style={{
              ...styles.backButton,
              ...(isArabic && { transform: 'rotate(180deg)' })
            }}
            onClick={() => this.props.navigation.goBack()}
          />

          <Typography
            style={{
              fontSize: '48px',
              fontWeight: 700,
              color: '#000000',
              marginTop: '48px'
            }}
          >
            {t['reviews-title']}
          </Typography>

          <ReviewsView
            getUserReviews={this.getUserReviews.bind(this)}
            createUserReview={this.createUserReview.bind(this)}
            pathParam={this.pathParam}
            gotoLogin={this.gotoLogin.bind(this)}
            reviewsList={this.state.reviewsList}
            showCreateReviewModal={this.state.showCreateReviewModal}
            setShowCreateReviewModal={this.setShowCreateReviewModal.bind(this)}
          />
        </Box>
        {/* footer */}
        <AppFooter navigation={this.props.navigation} />
      </Box>
    );
  }
}

const styles = {
  serviceBlock: {
    marginTop: '20px',
    backgroundColor: '#ffffff',
    padding: '16px'
  },
  backButton: {
    color: '#0F172A',
    cursor: 'pointer'
  },
  reviewThumbnail: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    border: '2px solid #CBD5E1',
    cursor: 'pointer'
  },
  modalPicture: {
    maxWidth: '80vw',
    maxHeight: '90vh'
  },
  imgIcon: {
    display: 'block',
    objectFit: 'cover',
    borderRadius: '8px',
    width: '100%',
    height: '100%'
  }
};
// Customizable Area End
