import React from "react"
// Customizable Area Start
import './SubCategories.css'
import { AppHeader } from '../../../components/src/AppHeader.web'

import { AppFooter } from '../../../components/src/AppFooter.web'
import LandingPageController from "./LandingPageController"
import { imgI147504895236797486HTpe556x7cBoLvN2ZuRRme, categoryDefaultImage } from './assets'
import { BreadCrumbs } from "../../../components/src/BreadCrumbs.web"
import { getStars, getTransContent, getTranslationConfig } from '../../../components/src/helpers'
import { Box, Button, Card, CardContent, Typography, Grid, Pagination } from '@mui/material';
import Loader from "../../../components/src/Loader.web";

interface Props {
  navigation: any;
  id: string
}

export default class SubCategories extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const promises = [
      this.getSingleCategoriesData(),
      this.getSubCategoriesData(),
    ];

    await Promise.allSettled(promises);

    this.setState({ subCatLoading: false });
  }


  navigationMap = {
    Home: 'LandingPage',
  };

  handleNavigate = (route: string) => {
    this.props.navigation.navigate(route);
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value }, () => {
      this.updatePaginatedVenues();
      window.scrollTo(0, 0);
    });
  };

  render() {
    const { t, dir } = getTranslationConfig();
    const { imageLoading, paginatedVenues, totalVenues, venuesData, currentPage, venuesPerPage, categoryData } = this.state;
    const { name, description } = getTransContent(categoryData.attributes);
    const pageCount = Math.ceil(totalVenues / venuesPerPage);    
    
    return (
      <Box style={{ backgroundColor: "#E8F6F4" }}>
        <AppHeader onClickEvent={() => this.goToLogin()} forceUpdate={this.forceUpdate.bind(this)} />
        <Box dir={dir}>
          <Box>
            <div
              className="hero-section"
              style={{
                backgroundImage: imageLoading && venuesData?.[0]?.cat_image
                  ? `url(${venuesData[0]?.cat_image})`
                  : `url(${imgI147504895236797486HTpe556x7cBoLvN2ZuRRme})`,
                paddingTop: 50,
                position: 'relative',
              }}
            >
              <div className="breadcrumb" style={{ marginTop: 10 }}>
                {!this.state.subCatLoading ? (
                  <BreadCrumbs
                    items={[t['breadcrumbs-home'], name]}
                    targetColor="#FFFFFF"
                    navigationMap={this.navigationMap}
                    navigate={this.handleNavigate}
                  />
                ) : (
                  <Loader subCatLoading={true} size={70} />
                )}
              </div>
              <div
                style={{
                  zIndex: 1,
                  color: '#fff',
                  fontFamily: 'Ubuntu',
                  fontWeight: 700,
                  fontSize: '48px',
                  lineHeight: '56px',
                  letterSpacing: '-1.5%',
                  marginLeft: "8.8rem",
                  marginTop: "50px"
                }}
              >
                <h1>{name}</h1>

              </div>
              <div
                style={{
                  zIndex: 1,
                  color: '#fff',
                  fontFamily: 'Ubuntu',
                  fontWeight: 700,
                  fontSize: '48px',
                  lineHeight: '56px',
                  letterSpacing: '-1.5%',
                  marginLeft: "8.8rem",
                  marginTop: "50px"
                }}
              >
                <h1>{description}</h1>

              </div>
            </div>
          </Box>
          <Box sx={{ px: 20, mt: 5 }}>
            <Typography variant="h4" gutterBottom
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 700,
                fontSize: '36px',
                lineHeight: '44px',
                letterSpacing: '-1%',
                textTransform: 'none'
              }}
            >
              {`${name} ${t['venues-service-postfix']}`}
            </Typography>
            <Typography variant="body1"
              style={{
                fontFamily: 'Ubuntu',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
                marginBottom: "30px"
              }}>
              {t['venues-service-description']}
            </Typography>
            <>
              {paginatedVenues && paginatedVenues.length > 0 ? (
                <>
                  <Grid container spacing={4}>
                    {paginatedVenues?.map((venue, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card style={{
                          height: "38rem",
                          borderRadius: "10px",
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2), 10px 0 20px rgba(0, 0, 0, 0.3)',
                          border: 'none',
                          overflowY: "auto",
                        }}>
                          <Box
                            marginBottom={2}
                            style={{
                              background: `linear-gradient(
                            rgba(0, 0, 0, 0.2), 
                            rgba(0, 0, 0, 0.5)
                          ), url(${venue.images || venue.cat_image || categoryDefaultImage}) center center / cover no-repeat`,
                              width: '100%',
                              height: "20rem",
                              boxSizing: 'border-box',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Button variant="contained"
                              style={{
                                backgroundColor: "#E8F6F4",
                                padding: 8,
                                marginTop: "16rem",
                                width: "90%",
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                cursor: "pointer"
                              }}
                              onClick={() => this.goToVenue(venue.spa_id)}
                            >
                              <Typography style={{
                                color: "#398378",
                                fontFamily: "Ubuntu",
                                fontWeight: 700,
                                fontSize: 16,
                                textTransform: 'none'
                              }}>
                                {t['venues-service-book-btn']}
                              </Typography>
                            </Button>
                          </Box>

                          <CardContent>
                            <Typography
                              style={{
                                fontFamily: "Ubuntu",
                                fontWeight: 700,
                                fontSize: 16,
                              }}
                            >
                              {venue.spa_name}
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Ubuntu",
                                fontWeight: 400,
                                fontSize: 14,
                                color: "#334155",
                                marginTop: "10px"
                              }}
                            >
                              {venue.address}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                              <Typography style={{ width: "24px", height: "24px", display: "flex", alignItems: "center" }}>
                                {getStars(venue.rating)} ({venue.rating.toFixed(1)})
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontFamily: 'Ubuntu',
                                  fontWeight: 400,
                                  fontSize: '14px',
                                  lineHeight: '22px',
                                  marginBottom: '8px',
                                  marginTop: "5px"
                                }}>
                                {getTransContent(venue).description}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                backgroundColor: "#398378",
                                borderRadius: "8px",
                                marginTop: "20px",
                              }}>
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  lineHeight: '22px',
                                  marginBottom: '8px',
                                  fontFamily: 'Ubuntu',
                                  fontWeight: 400,
                                  color: "#FFFFFF",
                                  padding: "20px"
                                }}
                              >
                                {venue.services.map((service: any[]) => service[1]).join(', ')}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
                    <Pagination
                      dir='ltr'
                      count={pageCount}
                      page={currentPage}
                      onChange={this.handlePageChange}
                      sx={{ color: '#398378' }}
                    />
                  </Box>
                </>
              ) : (
                <Typography variant="body1" align="center"
                  sx={{
                    mt: 4,
                    mb: 4,
                    fontFamily: 'Ubuntu',
                    fontWeight: 700,
                    fontSize:"24px"
                  }}>
                  Spas are not available for this category.
                </Typography>
              )}
            </>
          </Box>
        </Box>
        <AppFooter navigation={this.props.navigation} />
      </Box>
    );

  }
}
