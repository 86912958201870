import React from "react";
import { Box, Button, Modal, Typography, styled } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LandingPageController, { Props } from '../LandingPageController';
import { TypographyView } from '../../../ordermanagement1/src/styledFont';

const CustomButton = styled(Button)({

});

export default class SpaLoginModal extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { openLoginModal, setOpenLoginModal } = this.props;

        if (!setOpenLoginModal) {
            return null;
        }

        return (
            <Modal
                data-test-id='login-modal'
                open={Boolean(openLoginModal)}
                onClose={() => setOpenLoginModal(false)}
            >
                <TypographyView>
                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: '45%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: '#FFFFFF',
                            borderRadius: '8px 8px 32px 8px',
                            outline: 'none',
                            maxWidth: '80%',
                            minWidth: '20rem',
                        }}
                    >
                        <Box p={2} borderBottom='1px solid #E2E8F0'>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <CloseIcon
                                    data-test-id='login-close-btn'
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 'auto'
                                    }}
                                    onClick={() => setOpenLoginModal(false)}
                                />
                            </Box>
                            <Box px={5} py={2}>
                                <Typography
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: 700,
                                        marginBottom: '8px',
                                    }}
                                >
                                    Sign Up to Join as SPA Owner
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: '18px',
                                        paddingBottom: '2px',
                                    }}
                                >
                                    To join as a SPA owner, you must sign up with a new email address. Existing email addresses already in use cannot be used for registration as a SPA owner
                                </Typography>
                            </Box>
                        </Box>
                        <Box p={3}>
                            <Box
                                display='flex'
                                justifyContent='flex-end'
                                width='100%'
                            >
                                <CustomButton
                                    data-test-id='login-btn'
                                    variant="contained"
                                    color="primary"
                                    className="agreeButton"
                                    style={{
                                        fontWeight: 700,
                                        lineHeight: "24px",
                                        fontFamily: "Ubuntu",
                                        fontSize: '16px',
                                        width: "190px"
                                    }}
                                    onClick={() => this.goToSpaRegistration()}
                                >
                                    Login
                                </CustomButton>
                            </Box>
                        </Box>
                    </Box>
                </TypographyView>
            </Modal >
        );
    }
}